import Typography from '@material-ui/core/Typography';
import React from 'react';
import { format, parseISO } from 'date-fns';

const DateFormatter = (props) => {
  const { onClick, emptyText, column, row, defaultValue } = props;
  const value = defaultValue || props?.value || row[column?.id] || row[column?.key];

  if (value === null || value === undefined) {
    return <Typography onClick={onClick}>{emptyText}</Typography>;
  }

  let date, time;
  try {
    const parsedDate = parseISO(value);
    date = format(parsedDate, 'yyyy-MM-dd');
    time = value.includes('T') || value.includes(' ') ? format(parsedDate, 'HH:mm:ss') : null;
  } catch (error) {
    return <Typography onClick={onClick}>Invalid date</Typography>;
  }

  return (
    <div className='flex w-full items-start justify-center flex-col'>
      {time && <span className='font-medium text-sm'>{time}</span>}
      <span className='font-normal text-sm'>{date}</span>
    </div>
  );
};

export default DateFormatter;
