import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const UrlContext = React.createContext({});

export const UrlContextProvider = ({ children }) => {
  const search = useLocation().search;
  const history = useHistory();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    const urlSearch = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearch.entries());
    setSearchParams(params);
  }, [search]);

  /**
   * Set url parameters
   * @param {Object<String,any>} newParams Parameter name
   * @param {Boolean} withHistory
   */
  const setUrlParams = (newParams, withHistory = false) => {
    for (const key in newParams) {
      urlSearchParams.set(key, newParams[key]);
    }
    const method = withHistory ? 'push' : 'replace';
    history[method](`${window.location.pathname}?${urlSearchParams}`);
  };

  /**
   * Set url parameter
   * @param {String} param Parameter name
   * @param {any} value Parameter value
   * @param {Boolean} withHistory
   */
  const setUrlParam = (param, value, withHistory = false) => {
    setUrlParams({ [param]: value }, withHistory);
  };

  /**
   * Remove url parameters
   * @param {Array<String>} params Parameters to remove from query
   * @param {Boolean} withHistory
   */
  const removeParams = (params, withHistory = false) => {
    for (const param of params) {
      urlSearchParams.delete(param);
    }
    const method = withHistory ? 'push' : 'replace';
    history[method](`${window.location.pathname}?${urlSearchParams}`);
  };

  /**
   * Remove url parameter
   * @param {String} param Parameter to remove from query
   * @param {Boolean} withHistory
   */
  const removeParam = (param, withHistory = false) => {
    removeParams([param], withHistory);
  };

  /**
   * Clear all URL parameters
   * @param {Boolean} withHistory
   */
  const clearParams = (withHistory = false) => {
    const method = withHistory ? 'push' : 'replace';
    history[method](window.location.pathname);
    setSearchParams({});
  };

  return (
    <UrlContext.Provider
      value={{ searchParams, setUrlParams, setUrlParam, removeParams, removeParam, clearParams }}
    >
      {children}
    </UrlContext.Provider>
  );
};

/**
 * Hook to get and set url query parameters
 * @returns {{
 *    searchParams: Object<String,any>,
 *    setUrlParams: Function,
 *    setUrlParam: Function,
 *    removeParams: Function,
 *    removeParam: Function,
 *    clearParams: Function
 * }}
 */
const useQueryParams = () => useContext(UrlContext);

export default useQueryParams;
