import React from 'react';
import { Tooltip } from 'antd';

const IntegrationFormatter = ({ row }) => {
  if (!row?.source) {
    return null;
  }

  return (
    <Tooltip
      placement='top'
      title={<span className='capitalize'>{row.source}</span>}
      destroyTooltipOnHide={true}
      mouseEnterDelay={0.1}
    >
      <span className='w-full flex items-center m-auto'>
        <img
          src={`/icons/integrations/${row.source.toLowerCase()}.svg`}
          alt={row.source}
          className='w-5 h-5 m-auto'
        />
      </span>
    </Tooltip>
  );
};

export default IntegrationFormatter;
