import React, { useEffect, useState } from 'react';
import { Typography, Popover, Flex, Spin } from 'antd';
import PropTypes from 'prop-types';
import { mdiChevronDown, mdiChevronRight, mdiPencil } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';
import ninjaApi from 'store/redux/apis';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import AssignSourceAndStatus from './AssignSourceAndStatus';

const DealStatusFormatter = (props) => {
  const { onChange, status, editable = true } = props;
  const { setUrlParams } = useQueryParams();
  const [getDealStatusListQuery, { isLoading }] = ninjaApi.useLazyDealStatusListQuery();
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpenChange = () => {
    setOpen(!open);
  };

  const convertHexToRgba = (hex, opacity = 0.1) => {
    const cleanHex = hex.replace('#', '');
    const bigint = parseInt(cleanHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  useEffect(() => {
    if (open && status?.flow_id) {
      getDealStatusListQuery({ deal_status_flow_id: status.flow_id })
        .unwrap()
        .then((data) => setOptions(data.data));
    }
  }, [open, status?.flow_id]);

  const icons = [
    {
      icon: mdiPencil,
      tooltip: 'Edit',
      size: 0.7,
      onlyOnHover: true,
      onClick: () =>
        setUrlParams({ deal_configuration: true, tab: 'statuses', flow_id: status?.flow_id }),
    },
    { icon: open ? mdiChevronRight : mdiChevronDown, size: 0.7, onClick: handleOpenChange },
  ];

  return (
    <>
      {status ? (
        <Popover
          content={
            <div className='max-h-96 overflow-auto min-w-48'>
              {isLoading ? (
                <Spin size='small' />
              ) : (
                options.map((option) => (
                  <div
                    onClick={() => {
                      onChange?.(option.id), setOpen(!open);
                    }}
                    key={option.id}
                    value={option.id}
                    className='cursor-pointer p-2 hover:bg-background-light rounded-md'
                  >
                    <div className='!flex !items-center gap-1 py-1'>
                      <div
                        className='w-2 h-2 rounded-full'
                        style={{
                          background: option.color,
                          marginRight: 8,
                        }}
                      />
                      <span className='text-sm font-normal' style={{ color: option.color }}>
                        {option.name}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
          }
          trigger='click'
          open={open}
          placement='rightBottom'
          onOpenChange={handleOpenChange}
          className='cursor-pointer'
        >
          <div
            className='absolute right-0 top-0 w-full h-full flex items-center justify-between p-2 border pl-4'
            style={{ background: status.color ? convertHexToRgba(status?.color, 0.1) : 'white' }}
          >
            <div className='flex items-center gap-2 w-full justify-between relative'>
              <Flex align='center' gap='small'>
                <div
                  style={{
                    backgroundColor: status?.color,
                  }}
                  className='w-3 h-3 rounded-full'
                />
                <Typography className='text-xs font-semibold' style={{ color: status?.color }}>
                  {status.name}
                </Typography>
              </Flex>
              {editable && <TableIcons color={Ninja.colors.light} icons={icons} />}
            </div>
          </div>
        </Popover>
      ) : (
        <div className='relative w-full flex items-center justify-between'>
          <Typography className='text-xs font-normal text-info'>No Status</Typography>
          {editable && (
            <TableIcons
              color={Ninja.colors.light}
              icons={[
                {
                  icon: mdiPencil,
                  tooltip: 'Edit',
                  size: 0.7,
                  onlyOnHover: true,
                  onClick: () => setOpen(true),
                },
              ]}
            />
          )}
          <AssignSourceAndStatus
            open={open}
            onCancel={() => setOpen(false)}
            onChange={(value) => {
              onChange?.(value), setOpen(!open);
            }}
          />
        </div>
      )}
    </>
  );
};

export default DealStatusFormatter;

DealStatusFormatter.propTypes = {
  value: PropTypes.number,
};
