import React from 'react';
import { Statistic } from 'antd';
import Ninja from '@ninja';
import Currency from 'components/workspace/Currency';

const ComparableStatistic = (props) => {
  const { current = 0, prefix, currency = false, ...rest } = props;

  return (
    <Statistic
      value={current}
      valueStyle={{
        color: current > 0 ? Ninja.colors.success : Ninja.colors.error,
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.8rem !important',
        fontWeight: 400,
      }}
      prefix={
        prefix ? (
          prefix
        ) : currency ? (
          <Currency />
        ) : (
          <span className='text-sm'>{current > 0 ? '▲' : '▼'}</span>
        )
      }
      {...rest}
    />
  );
};

ComparableStatistic.propTypes = {};

export default ComparableStatistic;
