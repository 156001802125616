/**
 * Create chunks from given files
 * @param {Array<File>|File} file
 * @returns {Integer}
 */
export default function getFileSize(file) {
  // If single file given
  if (!Array.isArray(file)) {
    return file.size;
  }

  // If array given map through files and return sum of sizes
  return file.reduce((size, f) => {
    size += f.size;
    return size;
  }, 0);
}
