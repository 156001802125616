import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Typography, Popover } from '@material-ui/core';
import ninjaApi from 'store/redux/apis';

const NetworkAccountPopover = (props) => {
  const { id, open, ...rest } = props;
  const [getCampaignDetailsQuery, { data: campaign = {}, isLoading }] =
    ninjaApi.useLazyCampaignDetailsQuery();

  useEffect(() => {
    if (!open) return;
    getCampaignDetailsQuery({ campaign_id: id }).unwrap();
  }, [open]);

  return (
    <Popover
      className='pointer-events-none'
      open={open}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...rest}
    >
      {isLoading ? (
        <div className='flex justify-between space-x-8 p-3 min-w-[5rem]'>
          <CircularProgress />
        </div>
      ) : (
        <div className='p-3 flex justify-between align-start space-x-12 '>
          <div>
            <Typography variant='h6' className='flex items-center gap-2'>
              Campaign:
              <span className='!text-xs !font-normal'>{campaign.campaign}</span>
            </Typography>
            <Typography variant='h6' className='flex items-center gap-2'>
              Account:
              <span className='text-xs font-normal'>{campaign.account}</span>
            </Typography>
            <Typography variant='h6' className='flex items-center gap-2'>
              Currency:
              <span className='text-xs font-normal'>{campaign.currency}</span>
            </Typography>
          </div>
          <div>
            <Typography variant='h6' className='flex items-center gap-2'>
              Network:
              <img className='w-3' src={`/icons/networks/${campaign.network}.svg`} alt='social' />
            </Typography>
            <Typography variant='h6' className='flex items-center gap-2'>
              Spent:
              <span className='text-xs font-normal'>{campaign.spent}</span>
            </Typography>
            <Typography variant='h6' className='flex items-center gap-2'>
              Adsets:
              <span className='text-xs font-normal'>{campaign.adsets}</span>
            </Typography>
          </div>
        </div>
      )}
    </Popover>
  );
};

export default NetworkAccountPopover;
