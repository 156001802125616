import React from 'react';
import PropTypes from 'prop-types';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';

const CellFormatter = ({ column, row }) => {
  return (
    <>
      <div className='text-left text-xs flex items-center gap-1 font-normal w-full justify-between truncate'>
        {row[column.key]}
      </div>
      <Icon
        path={mdiPencil}
        size={1}
        className='editIcon pointer bg-info-background text-info rounded-2xl p-1'
      />
    </>
  );
};

export default CellFormatter;

CellFormatter.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
};
