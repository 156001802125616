import { Popconfirm } from 'antd';
import React from 'react';
import { AlertConfig } from './const';

const Confirmation = (props) => {
  const { type = 'warning', children } = props;
  const alertConfig = AlertConfig[type];

  return (
    <Popconfirm
      icon={<img src={alertConfig.icon} alt={`${type} icon`} />}
      className='!text-sm flex items-center justify-center w-full'
      okText='Yes'
      cancelText='No'
      placement='left'
      {...props}
    >
      {children}
    </Popconfirm>
  );
};

export default Confirmation;
