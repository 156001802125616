import React, { useEffect, useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import ninjaApi from 'store/redux/apis';
import { DateFormatter, IntegrationFormatter } from 'components/tables/NinjaTable/formatters';
import { DealStatusFormatter } from 'modules/CRMModule/formatters';
import { Descriptions, Spin } from 'antd';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

const DealInfoDrawer = (props) => {
  const { searchParams } = useQueryParams();
  const { deal_id } = searchParams;

  const [getGetSingleDealQuery, { isLoading }] = ninjaApi.useLazyGetSingleDealQuery();
  const [dealData, setDealData] = useState(null);

  useEffect(() => {
    getGetSingleDealQuery({ id: deal_id })
      .unwrap()
      .then((data) => {
        setDealData(data.data);
      })
      .catch(() => setDealData(null));
  }, [deal_id]);

  const items = dealData
    ? [
        { label: 'ID', children: dealData.id || '-' },
        { label: 'Contact Email', children: dealData.contact_email || '-' },
        { label: 'Source', children: <IntegrationFormatter row={dealData} /> || '-' },
        {
          label: 'Name',
          children: dealData.name || '-',
        },
        dealData?.url?.ad.id && {
          label: 'Ad',
          children: (
            <span className='bg-info-background text-color-black font-normal rounded-2xl p-1 px-2'>
              {dealData.url.ad.name || '-'}
            </span>
          ),
        },
        dealData?.url?.ad?.adset.id && {
          label: 'Adset',
          children: (
            <span className='bg-info-background text-color-black font-normal rounded-2xl p-1 px-2'>
              {dealData.url.ad.adset.name || '-'}
            </span>
          ),
        },
        dealData?.url?.ad?.adset?.campaign && {
          label: 'Campaign',
          children: (
            <span className='bg-info-background text-color-black font-normal rounded-2xl p-1 px-2'>
              {dealData?.url?.ad?.adset.campaign.name || '-'}
            </span>
          ),
        },
        {
          label: 'Status',
          children: <DealStatusFormatter status={dealData.status} editable={false} />,
        },
        {
          label: 'COGS',
          children: getFormattedValue(dealData.cogs, dealData.currency),
        },
        {
          label: 'Value',
          children: getFormattedValue(dealData.value, dealData.currency),
        },
        {
          label: 'Profit',
          children: getFormattedValue(dealData.profit, dealData.currency),
        },
        {
          label: 'Synced',
          children: dealData.created_at ? (
            <DateFormatter defaultValue={dealData.created_at} />
          ) : (
            '-'
          ),
        },
        {
          label: 'Order Placed',
          children: dealData.date ? <DateFormatter defaultValue={dealData.date} /> : '-',
        },
      ].filter(Boolean)
    : [];

  return (
    <AutomaticDrawer size='large' title={`Deal ID  ${deal_id}`} {...props}>
      {isLoading ? (
        <Spin size='large' className='m-auto w-full' />
      ) : (
        <Descriptions
          labelStyle={{
            height: 45,
            fontSize: '14px',
            fontWeight: 500,
            color: 'black',
          }}
          bordered
          size='small'
          column={1}
          items={items}
        />
      )}
    </AutomaticDrawer>
  );
};

DealInfoDrawer.requiredParams = {
  deal_id: (p) => parseInt(p) > 0,
};

export default DealInfoDrawer;
