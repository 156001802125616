import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { mdiPencilCircle, mdiDownloadCircle, mdiUpload, mdiDeleteCircle } from '@mdi/js';
import FileManager from 'components/FileManager';
import UrlHelper from 'common/utils/url-helper';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';

const CrmFileUploadFormatter = ({ onChange, defaultValue, onDelete }) => {
  const [fileManagerOpen, setFileManagerOpen] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (defaultValue?.value) {
      setFileList([{ url: defaultValue?.value }]);
    } else {
      setFileList([]);
    }
  }, [defaultValue]);

  const onChoose = (selec) => {
    setFileList([
      {
        url: UrlHelper.getFileUrl(selec),
      },
    ]);
    setFileManagerOpen(false);
    onChange && onChange(selec);
  };

  const icons = [
    {
      icon: mdiPencilCircle,
      onClick: () => setFileManagerOpen(true),
      tooltip: 'Edit',
      size: 1,
    },
    { icon: mdiDownloadCircle, tooltip: 'Download', size: 1 },
    { icon: mdiDeleteCircle, onClick: onDelete, tooltip: 'Delete', size: 1 },
  ];

  return (
    <div className='flex items-center justify-between gap-1 w-full h-full'>
      {!fileList.length ? (
        <Button
          className='w-full h-10'
          icon={<Icon path={mdiUpload} size={0.6} />}
          onClick={() => setFileManagerOpen(true)}
        >
          <span className='text-xs'>Upload File</span>
        </Button>
      ) : (
        <div className='flex items-center justify-between gap-2 w-full truncate'>
          <div className='flex items-center justify-center gap-2'>
            <span className='max-w-[75px] truncate text-ellipsis text-xs'>{fileList[0]?.url}</span>
          </div>

          <div className='flex items-center justify-center gap-1 absolute right-1'>
            <TableIcons color={Ninja.colors.orange} icons={icons} />
          </div>
        </div>
      )}
      <FileManager
        multiple={false}
        filetypes='image'
        onChoose={onChoose}
        onClose={() => setFileManagerOpen(false)}
        open={fileManagerOpen}
      />
    </div>
  );
};
export default CrmFileUploadFormatter;

CrmFileUploadFormatter.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  onDelete: PropTypes.func,
};
