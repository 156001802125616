import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Select, Segmented } from 'antd';
import Alert from 'components/Dialogs/Alert';
import ninjaApi from 'store/redux/apis';

const fieldTypes = [
  { name: 'Text', value: 'text' },
  { name: 'Number', value: 'number' },
  { name: 'Email', value: 'email' },
  { name: 'Select', value: 'select' },
  { name: 'File', value: 'file' },
  { name: 'Image', value: 'image' },
];

const FieldForm = (props) => {
  const { isLoading, editingField, onChange } = props;
  const editing = editingField ? true : false;

  const [getFieldsTypeQuery, { data = [] }] = ninjaApi.useLazyFieldsTypeQuery();
  const [type, setType] = useState(null);

  const defaultValues = {
    type: 'text',
    convert_to: [],
    name: '',
    required: false,
    user_fillable: true,
  };

  const [fieldData, setFieldData] = useState({
    ...defaultValues,
    ...editingField,
  });

  useEffect(() => {
    setFieldData({
      ...defaultValues,
      ...editingField,
    });
  }, [editingField]);

  const handleField = async () => {
    onChange({
      ...fieldData,
    });
  };

  useEffect(() => {
    getFieldsTypeQuery({
      type: type,
    }).unwrap();
  }, [type]);

  return (
    <div>
      <Form layout='vertical'>
        <Form.Item label='Label'>
          <Input
            size='large'
            placeholder='Label'
            name='label'
            autoFocus
            value={fieldData.name}
            onChange={(e) => {
              setFieldData((ps) => ({ ...ps, name: e.target.value }));
            }}
          />
        </Form.Item>
        <Form.Item label='Type'>
          <Select
            size='large'
            label='Type'
            name='type'
            value={fieldData.type}
            onChange={(value) => {
              setFieldData((ps) => ({ ...ps, type: value }));
            }}
            options={fieldTypes}
          />
        </Form.Item>
        <Form.Item label='Field'>
          <Select
            size='large'
            placeholder='Select Field'
            allowClear
            value={fieldData.convert_to}
            loading={isLoading}
            onChange={(value) => {
              setFieldData((ps) => ({ ...ps, convert_to: value }));
            }}
            dropdownRender={(menu) => (
              <>
                <Segmented
                  size='medium'
                  className='mb-2'
                  onChange={(val) => setType(val)}
                  options={[
                    {
                      label: <span className='text-sm font-normal'>All</span>,
                      value: null,
                    },
                    {
                      label: <span className='text-sm font-normal'>Deal</span>,
                      value: 'deal',
                    },
                    {
                      label: <span className='text-sm font-normal'>Contact</span>,
                      value: 'contact',
                    },
                  ]}
                />
                {menu}
              </>
            )}
          >
            {data?.data?.map((field) => (
              <Select.Option key={field.key} value={field.key}>
                {field.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <div className='grid gap-4 my-5'>
        <div>
          <Checkbox
            className='font-semibold'
            checked={fieldData.required}
            onChange={(e) => {
              setFieldData((ps) => ({ ...ps, required: e.target.checked }));
            }}
          >
            Required
          </Checkbox>
          <Alert
            type='info'
            closable={false}
            description=' It makes field required in form and data will not be submitted, without filling thisinput.'
          />
        </div>
        <div>
          <Checkbox
            className='font-semibold'
            checked={fieldData.user_fillable}
            onChange={(e) => {
              setFieldData((ps) => ({ ...ps, user_fillable: e.target.checked }));
            }}
          >
            User Fillable
          </Checkbox>
          <Alert
            closable={false}
            type='info'
            description='If users have to submit this input on their own in your forms, please, check it.'
          />
        </div>
      </div>
      <Button
        size='large'
        className='m-auto'
        type='primary'
        loading={isLoading}
        onClick={handleField}
      >
        {editing ? 'Update' : 'Create'}
      </Button>
    </div>
  );
};

export default FieldForm;
