import React from 'react';
import NameDefinition from './NameDefinition';
import PropTypes from 'prop-types';

const CpcDefinition = ({
  textFormat = 'text-xs font-semibold w-fit text-background-dark',
  size,
}) => {
  return <NameDefinition name='CPC' definiton='Cost Per Click' textSize={textFormat} size={size} />;
};

export default CpcDefinition;

CpcDefinition.propTypes = {
  textFormat: PropTypes.string,
};
