import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import React from 'react';

const FileManagerSelectedFilesInfo = (props) => {
  const { totalFiles = 0, selected = 0, onUseClicked, onCropClicked, onDelete, ...rest } = props;
  const classes = useStyles();

  const canCrop = selected?.length === 1 && typeof onCropClicked === 'function';

  return (
    <div className={clsx('flex gap-2 items-center w-full', classes.container)} {...rest}>
      <div className='flex-grow'>
        <div className='flex gap-2'>
          <Button onClick={onUseClicked} variant='contained' color='secondary'>
            Use
          </Button>
          {canCrop ? (
            <Button onClick={onCropClicked} variant='outlined' color='secondary'>
              Crop
            </Button>
          ) : null}
          <IconButton onClick={onDelete}>
            <Icon size='1.5rem' path={mdiDelete} />
          </IconButton>
        </div>
      </div>
      <Typography color='text' variant='body1'>
        Selected {selected.length} of {totalFiles} Files
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: 80,
    padding: theme.spacing(0, 2),
    bottom: 8,
    borderRadius: 10,
    background: theme.palette.custom.backgroundLight,
  },
}));

export default FileManagerSelectedFilesInfo;
