import React, { useState, useEffect } from 'react';
import { DateRangePicker as DateRangePickerRaw } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import { Button, Checkbox } from 'antd';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import PropTypes from 'prop-types';
import useQueryParams from 'hooks/useQueryParams';
import './styles.css';
import { staticRanges } from './components/staticRanges';

const DateRange = (props) => {
  const { value, date_key, globalDate, onChange } = props;
  const [state, setState] = useState(value);
  const [changeGlobal, setChangeGlobal] = useState(globalDate);
  const { setUrlParams, removeParams } = useQueryParams();

  useEffect(() => {
    setState(value);
  }, [value]);

  const { setDate } = useWidgetDate(date_key);

  const handleChange = (item) => {
    setState({ ...state, ...item });

    const selectedStaticRange = staticRanges.find((range) => range.isSelected(item));
    const staticRangeLabel = selectedStaticRange ? selectedStaticRange.label : '';

    setState((prevState) => ({
      ...prevState,
      staticRangeLabel,
    }));

    if (typeof onChange === 'function') {
      onChange(item, staticRangeLabel);
    }

    const dates = {
      date_from: format(item.range.startDate, 'yyyy-MM-dd'),
      date_to: format(item.range.endDate, 'yyyy-MM-dd'),
      date_range_key: selectedStaticRange?.key || null,
    };

    if (selectedStaticRange?.key && changeGlobal) {
      setUrlParams({ date: selectedStaticRange?.key });
      removeParams(['date_from', 'date_to']);
    } else if (changeGlobal) {
      setUrlParams({ date_from: dates.date_from, date_to: dates.date_to });
      removeParams(['date']);
    }

    setDate({
      date_from: dates.date_from,
      date_to: dates.date_to,
      change_global: changeGlobal,
      date_range_key: selectedStaticRange?.key || null,
      date_key: date_key,
    });
  };

  return (
    <div className='foot'>
      <DateRangePickerRaw
        showDateDisplay={false}
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[state.range]}
        staticRanges={staticRanges}
        direction='horizontal'
        footerContent={
          <Checkbox
            className='px-2'
            onChange={(e) => setChangeGlobal(e.target.checked)}
            checked={changeGlobal}
          >
            <span style={{ fontSize: 13 }}>Apply Globally</span>
          </Checkbox>
        }
      />
    </div>
  );
};

DateRange.propTypes = {
  date_key: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  globalDate: PropTypes.bool,
};

export default DateRange;
