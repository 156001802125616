import React from 'react';
import { Descriptions } from 'antd';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { useGetPostbackDetailsQuery } from 'modules/PostbacksModule/api/postbacks.api';
import useQueryParams from 'hooks/useQueryParams';

const PostbackDetailsDrawer = (props) => {
  const { searchParams } = useQueryParams();
  const { postback_details_id } = searchParams;
  const { data, isLoading } = useGetPostbackDetailsQuery({ id: postback_details_id });

  function formatString(inputString) {
    const words = inputString.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const formattedString = capitalizedWords.join(' ');

    return formattedString;
  }

  if (isLoading) return null;

  return (
    <AutomaticDrawer size='large' title='Postback Sales' {...props}>
      {data !== undefined && (
        <Descriptions size='small' bordered title='Postback Details' column={2}>
          {Object.entries(data).map(([key, value]) => (
            <Descriptions.Item label={formatString(key)} key={key}>
              {typeof value === 'object' ? (
                <pre>{JSON.stringify(value, null, 2)}</pre>
              ) : (
                <span
                  className={`${
                    key === 'event_id' || key === 'user_id' ? 'cursor-pointer' : undefined
                  }`}
                >
                  {value}
                </span>
              )}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}
    </AutomaticDrawer>
  );
};

export default PostbackDetailsDrawer;

PostbackDetailsDrawer.requiredParams = {
  postback_details_id: (p) => parseInt(p) > 0,
};
