import api from 'common/api';

class AdCampaignService {
  static createCampaign(data) {
    return api.call('post', '/network/campaign/create', data).then(api.getData);
  }

  static autoComplete(data) {
    return api.call('post', '/network/campaign/autocomplete', data).then(api.getData);
  }

  static search(data) {
    return api.call('post', '/network/campaign/search', data).then(api.getData);
  }

  static syncInNetwork(data) {
    return api.call('post', '/network/campaign/sync', data).then(api.getData);
  }

  static changeStatus(data) {
    return api.call('post', '/network/campaign/change-status', data).then(api.getData);
  }
}

export default AdCampaignService;
