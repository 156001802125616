import { Button, Descriptions, Skeleton } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import NetworkAccountStatusSwitch from 'components/table-formatters/network-account/NetworkAccountStatusSwitch';
import useQueryParams from 'hooks/useQueryParams';
import React, { useMemo } from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountDescription = (props) => {
  const { accountId, editable = true, statusEditable = true, ...rest } = props;
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({ id: accountId });
  const { setUrlParams } = useQueryParams();

  const items = useMemo(
    () => [
      {
        key: 'network',
        label: 'Network',
        children: <NetworkIcon size='small' network={account?.data?.network} />,
      },
      {
        key: 'name',
        label: 'Name',
        children: account?.data?.name,
      },
      {
        key: '1',
        label: 'Status',
        children: <NetworkAccountStatusSwitch disabled={!statusEditable} row={{ account: account?.data }} />,
      },
      {
        key: '2',
        label: 'Timezone',
        children: account?.data?.timezone,
      },
      {
        key: '3',
        label: 'Currency',
        children: account?.data?.currency,
      },
      {
        key: '4',
        label: 'Account ID',
        children: account?.data?.account_id,
      },
      {
        key: '4',
        label: 'Connection Time',
        children: account?.data?.created_at,
      },
    ],
    [account]
  );



  return (
    <Skeleton loading={isFetching} active>
      <Descriptions
        title='Basic Info'
        size='small'
        column={1}
        bordered
        items={items}
        {...rest}
      />
    </Skeleton>
  );
};

export default NetworkAccountDescription;
