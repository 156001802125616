import React, { useState } from 'react';
import { Button, Card, Popover, Badge } from 'antd';
import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';

const NinjaTableFilter = ({ filterComponent }) => {
  const { filters } = useNinjaTable();
  const [open, setOpen] = useState(false);
  const filterCount = (filters) => {
    let count = 0;

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value !== null && value !== undefined && value.length !== 0) {
        if (key === 'date_from' || key === 'date_to') {
          count = 1;
        } else if (typeof value === 'object') {
          const nestedCount = Object.entries(value).filter(
            ([nestedKey, nestedValue]) =>
              nestedKey !== 'type' &&
              nestedValue !== null &&
              nestedValue !== undefined &&
              nestedValue !== ''
          ).length;
          if (nestedCount > 0) {
            count++;
          }
        } else if (value !== '') {
          count++;
        }
      }
    });

    return count;
  };

  return (
    <Popover
      placement='bottomLeft'
      mouseLeaveDelay={0.7}
      content={
        <Card size='small' className='w-[400px]'>
          {filterComponent}
        </Card>
      }
    >
      <Button
        size='large'
        type='text'
        onClick={() => setOpen(!open)}
        icon={
          <Badge count={filterCount(filters)} size='small'>
            <Icon path={mdiFilter} size={1} />
          </Badge>
        }
      />
    </Popover>
  );
};

export default NinjaTableFilter;
