import React from 'react';
import PropTypes from 'prop-types';
import CampaignsTable from 'modules/campaigns/tables/CampaignsTable';

const AccountInfoCampaignsTab = (props) => {
  const { dates, accountId, ...rest } = props;

  return (
    <div>
      <CampaignsTable
        {...rest}
        cardProps={{ disable: true }}
        defaultFilters={{
          network_account_id: accountId,
          ...dates,
        }}
      />
    </div>
  );
};

AccountInfoCampaignsTab.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountInfoCampaignsTab;
