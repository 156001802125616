import React from 'react';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';

export default function ValueFormatter(props) {
  let val = 0;

  // Get column value
  if (props.getValue) {
    val = props.getValue(props);
  } else {
    val = props.row[props.column.key];
  }

  if (val !== 0 && val !== null && val !== undefined) {
    val = Number(val);
    val = val % 1 !== 0 ? val?.toFixed(2) : val; // only fix decimals if needed
  } else {
    val = 0;
  }

  return <span className='font-normal text-xs'>{getFormattedNumber(val)}</span>;
}
