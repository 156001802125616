import ToggleSwitch from 'components/switches/ToggleSwitch';
import React, { useState, useEffect } from 'react';
import AdsetService from 'services/Network/AdSetService';
import AdCampaignService from 'services/Network/AdCampaignService';
import AdCreativeService from 'services/Network/AdCreativeService';
import { usePermissions } from 'store/redux/apis/workspace/permissions.api';
import { snackbar } from 'components/notifications/notifications';
import { Popconfirm } from 'antd';

const AdProcessFormatter = ({ row, level = 'adset' }) => {
  const [checked, setChecked] = useState(row?.status === 'active' || false);
  const { canEdit } = usePermissions();

  useEffect(() => {
    setChecked(row?.status === 'active');
  }, [row]);

  const changeStatus = async (service, type, active) => {
    try {
      setChecked(active);
      await service.changeStatus({
        id: row.id,
        status: active ? 'active' : 'paused',
      });
      snackbar.success('Status successfully changed.');
    } catch (error) {
      setChecked(!active);
      snackbar.error(`Failed to change status: ${error.message}`);
    }
  };

  const handleAdStatusChange = (active) => {
    if (!canEdit('reporting')) return;

    if (level === 'adset') {
      changeStatus(AdsetService, 'adset', active);
    } else if (level === 'campaign') {
      changeStatus(AdCampaignService, 'campaign', active);
    } else if (level === 'ad') {
      changeStatus(AdCreativeService, 'ad', active);
    }
  };

  return (
    <div className='flex items-center justify-between h-full gap-2'>
      <Popconfirm
        title={`Are you sure you want to turn ${checked ? 'off' : 'on'} this ${level}?`}
        onConfirm={() => handleAdStatusChange(!checked)}
        okText='Yes'
        cancelText='No'
        disabled={!canEdit('reporting')}
      >
        <ToggleSwitch id='switch' disabled={!canEdit('reporting')} checked={checked} />
      </Popconfirm>
    </div>
  );
};

export default AdProcessFormatter;
