import React, { useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { StatusSelectFormatter } from 'components/tables/NinjaTable/formatters';

const AssignSourceAndStatus = (props) => {
  const { onChange, onCancel } = props;
  const [status, setStatus] = useState();

  const handleOk = () => {
    onChange(status);
  };

  return (
    <Modal
      {...props}
      title='Assign Status'
      centered
      okText='Save'
      onOk={handleOk}
      onCancel={onCancel}
    >
      <StatusSelectFormatter
        size='large'
        placeholder='Select Status'
        onChange={(value) => setStatus(value)}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default AssignSourceAndStatus;

AssignSourceAndStatus.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};
