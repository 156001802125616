import { useEffect, useState } from 'react';
import { useUploadFilesMutation } from 'store/redux/states/filemanager/filemanager.api';
import chunkFile from 'components/FileManager/utils/chunkFile';
import getFileSize from '../utils/getFileSize';

const useFileUpload = (props = {}) => {
  const { onChunkUploaded, onFileUploaded, onUploadSuccess, onUploadError } = props;
  const [uploadFilesRequest] = useUploadFilesMutation();
  const [uploadedBytes, setUploadedBytes] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [filesLength, setFilesLength] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  // Calculate upload progress in percent
  const progress = uploadedBytes ? Math.round((uploadedBytes / fileSize) * 100) : 0;

  const inProgress = progress != 0 && progress != 100;

  /**
   * @param {File} file
   * @param {String} folder
   */
  const uploadFile = async (file, folder) => {
    const chunks = chunkFile(file);
    const chunk_data = { id: '' };

    for (let i = 0, l = chunks.length; i < l; i++) {
      const chunk = chunks[i];
      const data = new FormData();
      const is_last = typeof chunks[i + 1] === 'undefined';

      // Add current folder paths
      data.append('path', folder);
      data.append('file', chunk, file.name);
      data.append('is_last', is_last);
      data.append('is_chunk', true);
      data.append('chunk_id', chunk_data.id);

      await uploadFilesRequest(data)
        .unwrap()
        .then((res) => {
          // Update progress
          setUploadedBytes((bytes) => {
            bytes += chunk.size;
            return bytes;
          });

          // If chunk is uploaded
          if (res.chunk_id) {
            chunk_data.id = res.chunk_id;
            onChunkUploaded?.(res.chunk_id);
          }

          /** Already uploaded file */
          if (res.uploaded_file) {
            onFileUploaded?.(file, res.uploaded_file);
            setUploadedFiles((f) => {
              f.push(res.uploaded_file);
              return [...f];
            });
          }
        })
        .catch((err) => {
          onUploadError?.(file, err);
        });
    }
  };

  /**
   * Reset uploading state
   */
  const resetState = () => {
    setUploadedBytes(0);
    setFileSize(0);
    setFilesLength(0);
    setUploadedFiles([]);
    setUploadInProgress(false);
  };

  /**
   * Upload files
   * @param {Array<File>|File} files
   * @param {String} folder
   */
  const upload = async (files, folder) => {
    // Wrap array into files
    files = Array.wrap(files);

    // Set current uploaded filesize
    resetState();

    setFileSize(getFileSize(files));
    setFilesLength(files.length);

    setUploadInProgress(true);
    for (let i = 0, l = files.length; i < l; i++) {
      await uploadFile(files[i], folder);
    }
    setUploadInProgress(false);
  };

  useEffect(() => {
    console.log(filesLength, uploadedFiles);

    if (filesLength !== 0 && uploadedFiles.length === filesLength) {
      onUploadSuccess?.();
    }
  }, [uploadedFiles]);

  return {
    upload,
    progress,
    uploadedBytes,
    fileSize,
    inProgress,
    uploadedFiles,
    uploadInProgress,
  };
};

export default useFileUpload;
