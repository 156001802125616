import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import FilePreview from './FilePreview';

const FileManagerFiles = (props) => {
  const { height = '100vh', files, selected = [], onFileSelect } = props;

  const classes = useStyles({
    height: height,
  });

  const handleClick = (id) => {
    onFileSelect(id.toString());
  };

  return (
    <div className={clsx('grid gap-4', classes.container)}>
      {files.map((f) => {
        return (
          <FilePreview
            checkable={true}
            className=''
            key={f.id}
            id={f.id}
            name={f.name}
            type={f.type}
            thumbnail={f.is_folder ? '/images/folder-icon.png' : f.thumbnail}
            checked={selected.includes(f.id.toString())}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'minmax(min-content, max-content)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
}));

export default FileManagerFiles;
