import api from 'common/api';

class AdCreativeService {
  static createAdCreative(data) {
    return api.call('post', '/network/creative/create', data).then(api.getData);
  }
  static changeStatus(data) {
    return api.call('post', '/network/creative/change-status', data).then(api.getData);
  }
}

export default AdCreativeService;
