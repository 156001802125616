import React from 'react';
import { Button } from 'antd';
import Icon from '@mdi/react';
import { mdiLinkOff } from '@mdi/js';
import Confirmation from 'components/Dialogs/Confirmation';
import { useDisconnectIntegrationMutation } from 'modules/apps/api/apps.api';
import { snackbar } from 'components/notifications/notifications';

const DisconnectButton = ({ id, integration, name }) => {
  const [disconnectIntegration, { isLoading }] = useDisconnectIntegrationMutation();

  const handleDisconnect = () => {
    disconnectIntegration({ integration_id: id, integration_type: integration })
      .unwrap()
      .then(() => {
        snackbar.success(`${name} successfully disconnected.`);
      })
      .catch((err) => {
        console.log(err);
        snackbar.error(`Error disconnecting ${name}.`);
      });
  };

  return (
    <Confirmation
      type='warning'
      onConfirm={handleDisconnect}
      description='Are you sure you want to diconnect store'
    >
      <Button
        loading={isLoading}
        icon={<Icon path={mdiLinkOff}  size={0.6}/>}
      >
        Disconnect
      </Button>
    </Confirmation>
  );
};

export default DisconnectButton;
