import React from 'react';
import NameDefinition from './NameDefinition';
import PropTypes from 'prop-types';

const CpaDefinition = ({
  textFormat = 'text-xs font-semibold w-fit text-background-dark',
  size,
}) => {
  return (
    <NameDefinition name='CPA' definiton='Cost Per Acquisition' textSize={textFormat} size={size} />
  );
};

export default CpaDefinition;

CpaDefinition.propTypes = {
  textFormat: PropTypes.string,
};
