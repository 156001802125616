import React, { useState, useEffect } from 'react';
import { Modal, Upload } from 'antd';
import { mdiPencil, mdiEyeOutline, mdiPlus, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { makeStyles } from '@material-ui/core';
import FileManager from 'components/FileManager';
import UrlHelper from 'common/utils/url-helper';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import PropTypes from 'prop-types';

const CrmImageUploadForamtter = ({ onChange, defaultValue, onDelete }) => {
  const [fileManagerOpen, setFileManagerOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (defaultValue?.value) {
      setFileList([{ url: defaultValue.value }]);
    } else {
      setFileList([]);
    }
  }, [defaultValue]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = () => {
    setPreviewImage(defaultValue?.value);
    setPreviewOpen(true);
  };

  const onChoose = (selectedImage) => {
    const newFileList = [{ url: UrlHelper.getFileUrl(selectedImage) }];
    setFileList(newFileList);
    setFileManagerOpen(false);
    onChange && onChange(selectedImage);
  };

  const icons = [
    {
      icon: mdiEyeOutline,
      onClick: handlePreview,
      tooltip: 'Preview',
      size: 1,
      className: 'bg-orange rounded-full p-1',
    },
    {
      icon: mdiPencil,
      onClick: () => setFileManagerOpen(true),
      tooltip: 'Edit',
      size: 1,
      className: 'bg-orange rounded-full p-1',
    },
    {
      icon: mdiDelete,
      onClick: onDelete,
      tooltip: 'Delete',
      size: 1,
      className: 'bg-orange rounded-full p-1',
    },
  ];
  return (
    <div className={classes.root}>
      <Upload
        listType='picture-card'
        fileList={fileList}
        customRequest={() => {}}
        openFileDialogOnClick={false}
        open={fileManagerOpen}
        onChange={() => setFileList(fileList)}
        showUploadList={{
          showPreviewIcon: false,
          showDownloadIcon: false,
          showRemoveIcon: true,
          removeIcon: (
            <div className='flex w-full item-center justify-center gap-2'>
              <TableIcons color={Ninja.colors.white} icons={icons} />
            </div>
          ),
        }}
      >
        {fileList.length < 1 && (
          <span
            onClick={() => setFileManagerOpen(true)}
            className='hover:text-orange text-xs font-medium flex items-center gap-1'
          >
            <Icon path={mdiPlus} size={0.5} />
            <span>Upload</span>
          </span>
        )}
      </Upload>
      <Modal visible={previewOpen} footer={null} onCancel={handleCancel}>
        <img className={classes.previewImage} src={previewImage} alt='Preview' />
      </Modal>

      <FileManager
        multiple={false}
        filetypes='image'
        onChoose={onChoose}
        onClose={() => setFileManagerOpen(false)}
        open={fileManagerOpen}
      />
    </div>
  );
};

export default CrmImageUploadForamtter;

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    width: '100%',
    height: '100%',
    '& .ant-upload-wrapper': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px !important',
    },
    '& .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px !important',
    },
    '& .ant-upload-wrapper .ant-upload-list ': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      border: 'none',
    },
    '& .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select ': {
      height: '100% !important',
      width: '100%',
      marginBlock: '0',
      marginInline: '0',
      margin: '0 !important',
      borderRadius: 0,
      border: 'none',
    },
    '& .ant-upload-list-item-container': {
      height: '100% !important',
      width: '100%',
      marginBlock: '0',
      marginInline: '0',
      margin: '0 !important',
      borderRadius: 0,
      border: 'none',
    },
    '& .ant-upload-wrapper.ant-upload-picture-card-wrapper ': {
      width: '100%',
      height: '100%',
      marginBlock: '0',
      marginInline: '0',
    },
    '& .ant-upload-list-item-thumbnail': {
      width: '100%',
      height: '100% !important',
    },
    '& .ant-upload-list-item-image': {
      objectFit: 'cover !important',
    },
    '& .ant-upload-list-item-actions': {
      width: '100%',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      gap: 10,
    },
    '& .ant-upload-list-item-actions button': {
      width: '100% !important',
    },
    '& .ant-btn-icon': {
      width: '100%',
      height: '100% !important',
      padding: '0px 5px',
    },
    '& .ant-upload-list': { padding: '0px !important', borderColor: 'none !important' },
    '& .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before':
      {
        width: '100%',
        height: '100% !important',
      },
  },
  icon: {
    backgroundColor: '#F1F5F8',
    borderRadius: '50%',
    width: 23,
    height: 23,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    border: '1px solid #536a8b',
    '&:hover': {
      border: '1px solid #ff4e00',
      background: '#FBECEA',
      '& svg': {
        color: '#ff4e00 !important',
      },
    },
    '& svg': {
      color: '#536a8b !important',
    },
  },
}));

CrmImageUploadForamtter.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  onDelete: PropTypes.func,
};
