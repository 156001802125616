import React from 'react';
import { Tooltip } from 'antd';

const NetworkFormatter = ({ row, title }) => {
  if (!row?.network) {
    return null;
  }

  return (
    <Tooltip placement='top' title={title || row.network} destroyTooltipOnHide={true} mouseEnterDelay={0.1}>
      <span className='flex items-center justify-center'>
        <img src={`/icons/networks/${row.network.toLowerCase()}.svg`} alt={row.network} className="w-4 h-4" />
      </span>
    </Tooltip>
  );
};

export default NetworkFormatter;
