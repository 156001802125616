import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import { Typography, Button, Popover, Input, Avatar, Divider } from 'antd';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const CommentFormatter = ({ row, getComments, addComment }) => {
  const [open, setOpen] = useState(false);
  const { TextArea } = Input;
  const [comments, setComments] = useState([]);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (open) {
      loadComments();
    }
  }, [row, open]);

  const loadComments = async () => {
    const res = await getComments(row.id);
    setComments(res);
  };

  const handleAddComment = async () => {
    await addComment(inputValue, row.id);
    setInputValue('');
  };

  return (
    <div className='flex items-center justify-between gap-2 w-full' onClick={() => setOpen(true)}>
      {row.last_comment?.text ? (
        <Typography className='p-2 text-xs font-normal bg-info-background rounded-2xl flex-nowrap truncate text-ellipsis'>
          {row.last_comment?.text || 'Add Comment'}
        </Typography>
      ) : (
        <Typography className='p-2 font-normal text-xs opacity-60'>Add a comment</Typography>
      )}

      <Popover
        placement='rightBottom'
        content={
          <div className='grid gap-2 sm:max-w-[400px] max-w-52 w-full'>
            <div
              className=' w-full grid gap-2 sm:max-h-96 max-h-64 pr-2'
              style={{ overflow: 'auto' }}
            >
              {comments.data?.map((comment) => (
                <React.Fragment key={comment.id}>
                  <div>
                    <div className='flex gap-2'>
                      <Avatar alt='' icon={<img src={comment.user.avatar} />} />
                      <Typography className='text-xs font-normal'>
                        {comment.user.name} {comment.user.last_name}
                      </Typography>
                      <div className='flex-1' />
                      <Typography className='text-xs font-normal'>
                        {format(new Date(comment.created_at), 'dd-MM-yyyy HH:mm')}
                      </Typography>
                    </div>
                    <Typography className='text-xs font-semibold mt-4'>{comment.text}</Typography>
                  </div>
                  <Divider orientationMargin='0' />
                </React.Fragment>
              ))}
            </div>
            <TextArea
              autoSize={{
                minRows: 2,
                maxRows: 6,
              }}
              placeholder='Add a comment'
              value={inputValue}
              className='text-xs'
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />

            <div className='flex items center gap-2'>
              <Button size='medium' type='primary' onClick={handleAddComment}>
                Post
              </Button>
              <Button size='medium'>Cancel</Button>
            </div>
          </div>
        }
        trigger='click'
        open={open}
        onOpenChange={() => setOpen(!open)}
      >
        <Button
          size='small'
          type='text'
          shape='circle'
          className='bg-info-background text-info'
          icon={<Icon path={mdiPencil} size={0.6} />}
        />
      </Popover>
    </div>
  );
};

export default CommentFormatter;

CommentFormatter.propTypes = {
  row: PropTypes.object,
  getComments: PropTypes.func,
  addComment: PropTypes.func,
};
