import React, { useEffect, useState } from 'react';
import { mdiAnchor, mdiCursorDefaultClick, mdiFormDropdown } from '@mdi/js';
import Icon from '@mdi/react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import P from 'router/paths';

const items = [
  {
    label: 'CRM',
    key: 'deal',
    url: P.SALES.CRM,
    icon: <Icon path={mdiFormDropdown} />,

  },
  {
    label: 'Postbacks',
    url: P.SALES.POSTBACKS,
    key: 'pb',
    icon: <Icon path={mdiAnchor} />,

  },
  {
    label: 'Events',
    url: P.SALES.EVENTS,
    key: 'event',
    icon: <Icon path={mdiCursorDefaultClick} />,
  },
];

const SalesMainMenu = () => {
  const [selected, setSelected] = useState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const key = items.find((item) => item.url === location.pathname)?.key;
    setSelected(key);
  }, [location.pathname]);

  const onSelect = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem) {
      history.push(selectedItem.url);
      setSelected(key);
    }
  };

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
    </div>
  );
};

export default SalesMainMenu;
