import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DoubleTypography = (props) => {
  const {
    primary,
    primaryVariant = 'body2',
    primaryColor = 'textPrimary',
    primaryTypographyProps = {
      variant: primaryVariant,
      color: primaryColor,
    },
    secondary,
    secondaryVariant = 'caption',
    secondaryColor = 'textPrimary',
    secondaryTypographyProps = {
      variant: secondaryVariant,
      color: secondaryColor,
    },
    avatar = null,
    avatarContent,
    avatarProps,
    AvatarComponent = Avatar,
    avatarContainerProps = {},
    endAction = null,
    startAction = null,
    textProps = {},
    icon = null,
    iconPath = null,
    iconProps = {},
    iconColor = 'secondary',
    ...rest
  } = props;

  const prependIconOrAvatar = () => {
    if (avatar || avatarContent) {
      return (
        <ListItemAvatar {...avatarContainerProps}>
          <AvatarComponent src={avatar} {...avatarProps}>
            {avatarContent}
          </AvatarComponent>
        </ListItemAvatar>
      );
    } else if (iconPath) {
      return (
        <ListItemIcon {...avatarContainerProps}>
          <SvgIcon {...iconProps} color={iconColor}>
            <path d={iconPath} />
          </SvgIcon>
        </ListItemIcon>
      );
    } else if (icon) {
      return <ListItemIcon {...avatarContainerProps}>{icon}</ListItemIcon>;
    }

    return null;
  };

  return (
    <ListItem disableGutters dense {...rest}>
      {prependIconOrAvatar()}
      {startAction}
      <ListItemText
        primary={primary}
        primaryTypographyProps={primaryTypographyProps}
        secondaryTypographyProps={secondaryTypographyProps}
        secondary={secondary}
        {...textProps}
      />
      {endAction}
    </ListItem>
  );
};

DoubleTypography.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  primaryVariant: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryVariant: PropTypes.string,
  secondaryColor: PropTypes.string,
  avatar: PropTypes.string,
  avatarContent: PropTypes.elementType,
  avatarProps: PropTypes.object,
  avatarContainerProps: PropTypes.object,
  textProps: PropTypes.object,
  endAction: PropTypes.elementType,
  startAction: PropTypes.elementType,
  iconPath: PropTypes.string,
  icon: PropTypes.elementType,
  iconProps: PropTypes.object,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
  AvatarComponent: PropTypes.elementType,
};

export default DoubleTypography;
