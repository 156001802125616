import React from 'react';
import { mdiFilter, mdiFilterOff } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';
import Ninja from '@ninja';
import P from 'router/paths';
import PropTypes from 'prop-types';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import { useHistory } from 'react-router-dom';
import Icon from '@mdi/react';
import { Typography, Flex } from 'antd';

const DealFormatter = ({ row }) => {
  const { setUrlParams } = useQueryParams();
  const history = useHistory();

  const handleChange = () => {
    history.push(P.CRM.DEALS);

    if (row.email) {
      setUrlParams({ contact_email: row.email });
    } else {
      setUrlParams({ contact_ids: row.id });
    }
  };

  return (
    <div className='flex items-center justify-center w-full'>
      {row.deals_count === 0 ? (
        <Icon path={mdiFilterOff} color={Ninja.colors.xlight} />
      ) : (
        <Flex align='center' justify='center' gap={5}>
          <Typography>{row.deals_count}</Typography>
          <TableIcons
            color={Ninja.colors.light}
            icons={[{ icon: mdiFilter, onClick: handleChange, tooltip: 'Deal', size: 0.7 }]}
          />
        </Flex>
      )}
    </div>
  );
};

export default DealFormatter;

DealFormatter.propTypes = {
  row: PropTypes.object,
};
