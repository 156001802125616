import React from 'react';
import { mdiCircle } from '@mdi/js';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const statuses = {
  active: { color: Ninja.colors.success, name: 'Active' },
  paused: { color: Ninja.colors.error, name: 'Paused' },
  archived: { color: Ninja.colors.gray, name: 'Archived' },
  removed: { color: Ninja.colors.error, name: 'Removed' },
  deleted: { color: Ninja.colors.error, name: 'Deleted' },
};

const StatusFormatter = ({ row: { status }, title = true }) => {
  if (!status) {
    return null;
  }

  return (
    <TableIcons
      triggerHoverOnCell
      icons={[
        {
          icon: mdiCircle,
          color: statuses[status.toLowerCase()].color,
          tooltip: title && statuses[status?.toLowerCase()].name,
          size: '0.6rem',
        },
      ]}
    />
  );
};

export default StatusFormatter;
