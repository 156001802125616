import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import Currency from 'components/Currency';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import { Form, Input, Button } from 'antd';
import Urlconfig from 'modules/PostbacksModule/components/UrlConfig';
import Alert from 'components/Dialogs/Alert';

const PostbackForm = (props) => {
  const { editing = false, success, fields, initialData = null, loading, postback } = props;

  const onSubmit = () => {
    props.onSubmit && props.onSubmit(form.values);
  };

  const form = useFormik({
    initialValues: {},
    onSubmit: onSubmit,
    validateOnChange: true,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    form.setValues({
      name: initialData ? initialData.name : '',
      override_parameters: initialData ? initialData.override_parameters : '',
      currency: initialData ? initialData.currency : 'USD',
    });
  }, [initialData]);

  const handleUrlConfig = (c) => {
    form.setFieldValue('url', c.url);
    form.setFieldValue('fields', JSON.stringify(c.fields));
  };

  return (
    <Form className='flex flex-col' layout='vertical'>
      <Form.Item
        label='Name'
        hasFeedback
        {...formikFieldToAntProps(form.errors, form.touched, 'name')}
      >
        <Input
          size='large'
          placeholder='Enter Name'
          name='name'
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label='Currency'
        hasFeedback
        {...formikFieldToAntProps(form.errors, form.touched, 'currency')}
      >
        <div className='h-[3.3rem]'>
          <Currency
            size='large'
            value={form.values.currency}
            error={form.touched.currency && Boolean(form.errors.currency)}
            errorText={form.touched.currency && form.errors.currency}
            onChange={(val) => form.setFieldValue('currency', val)}
          />
        </div>
      </Form.Item>
      <Form.Item label='Override parameters'>
        <Input
          size='large'
          placeholder='Override parameters'
          name='override_parameters'
          value={form.values.override_parameters}
          onChange={form.handleChange}
        />
      </Form.Item>
      <Alert
        type='info'
        showIcon
        description='You can overried any postback parameter manually.
          For example, enter into ovverride input: value=5.5 
          this will change value parameter in this postback, whenever it comes to 5.5 '
      />
      <div className='my-8'>
        <Urlconfig
          onConfigChange={handleUrlConfig}
          url={postback?.url}
          postbackId={postback?.postback_id}
          fields={fields}
        />
      </div>
      <div className='flex-center'>
        <Button
          disabled={loading || (success && !editing)}
          onClick={form.handleSubmit}
          size='large'
          type='primary'
        >
          {editing ? 'Update' : 'Create'}
        </Button>
      </div>
    </Form>
  );
};

PostbackForm.propTypes = {
  editing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  initialData: PropTypes.object.isRequired,
  postback: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default PostbackForm;

const validationSchema = yup.object({
  name: yup.string('Enter Name').required('Name is required'),
  currency: yup.string('Enter Currency').required('Currency field is required'),
});
