import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';
import DoubleTypography from 'components/Typography/DoubleTypography';
import { topBarHeight } from '@ninja/consts';
import React from 'react';

const RightDrawer = (props) => {
  const {
    headerProps = {},
    width,
    children,
    padding,
    open,
    color,
    onClose,
    icon,
    title,
    containerClassName,
    ...rest
  } = props;

  const classes = useStyles({
    width: width,
    color: color,
    padding: padding || 2,
  });

  return (
    <Drawer
      onClose={onClose}
      anchor='right'
      classes={{
        paper: classes.drawerPaper,
      }}
      open={open}
      {...rest}
    >
      <div className={classes.header}>
        <DoubleTypography
          primaryVariant='h5'
          {...headerProps}
          endAction={
            <IconButton onClick={onClose}>
              <Icon path={mdiClose} />
            </IconButton>
          }
        />
      </div>
      <div className={clsx(classes.childrenContainer, containerClassName)}>{children}</div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    height: topBarHeight,
    borderBottom: '1px solid #dddddd',
  },
  drawerPaper: {
    width: (props) => props.width || 600,
    maxWidth: '90%',
  },
  childrenContainer: {
    width: '100%',
  },
  drawerTitle: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      textTransform: 'none',
      color: (props) => theme.colors[props.color || 'orange'],
      fontSize: (props) => props.fontSize || 14,
      lineHeight: '14px',
      fontWeight: 'bold',
      fontFamily: 'fregular',
      // marginLeft: 9,
    },
  },
}));

export default RightDrawer;
