import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { mdiCalendarMonth } from '@mdi/js';
import Icon from '@mdi/react';
import './style.css';

const RangePicker = (props) => {
  const { RangePicker } = DatePicker;
  const { value = [], onChange, placeholder = ['Start date', 'End date'], ...rest } = props;

  const rangePresets = [
    { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
    {
      label: 'Yesterday',
      value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
    },
    { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
    {
      label: 'Last Week',
      value: [
        dayjs().subtract(1, 'week').startOf('week'),
        dayjs().subtract(1, 'week').endOf('week'),
      ],
    },
    { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
    {
      label: 'Last Month',
      value: [
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
      ],
    },
  ];

  const onRangeChange = (dates, dateStrings) => {
    props.onChange({
      date_from: dateStrings[0] || null,
      date_to: dateStrings[1] || null,
    });
  };

  const formattedValue = [value[0] ? dayjs(value[0]) : null, value[1] ? dayjs(value[1]) : null];

  return (
    <RangePicker
      {...props}
      value={formattedValue}
      className='!text-sm'
      presets={rangePresets}
      onChange={onRangeChange}
      suffixIcon={<Icon className='text-orange' path={mdiCalendarMonth} />}
    />
  );
};

export default RangePicker;
