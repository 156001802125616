import React from 'react';
import { Tooltip, Typography } from 'antd';

const NameFormatter = ({ name }) => {
  return (
    <Tooltip placement='top' title={name} destroyTooltipOnHide mouseEnterDelay={0.1}>
      <Typography className='text-xs w-full overflow-hidden text-ellipsis text-left font-bold'>{name || '-'}</Typography>
    </Tooltip>
  );
};

export default NameFormatter;
