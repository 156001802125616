import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import PropTypes from 'prop-types';

export default function JustValueFormatter(props) {
  const { currency } = props;
  let val = 0;
  // Get column value
  if (props.getValue) {
    val = props.getValue(props);
  } else {
    val = props.row[props.column.key];
  }

  if (val !== 0 && val !== null && val !== undefined) {
    val = val.toFixed(0);
  } else {
    val = 0;
  }

  return (
    <span className='font-normal text-sm'>
      {getFormattedValue(val, currency ? currency : props.row.currency ? props.row.currency : null)}
    </span>
  );
}

JustValueFormatter.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  getValue: PropTypes.func,
  currency: PropTypes.string,
};
