import React from 'react';
import NetworkIcon from 'components/NetworkIcon';
import { Tooltip } from 'antd';
import StatusIcon from 'components/icons/StatusIcon';
import Icon from '@mdi/react';
import { mdiCog, mdiContentCopy, mdiDatabaseRemove, mdiInformation, mdiRefresh } from '@mdi/js';
import copyToClipboard from 'common/utils/copyToClipboard';
import useQueryParams from 'hooks/useQueryParams';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const NetworkAccountFormatter = ({ row, copy = true }) => {
  const { setUrlParams } = useQueryParams();

  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(row.name);
  };


  const openAccountInfo = (e) => {
    e.stopPropagation();

    setUrlParams({ account_info_id: row.id });
  };

  const onButtonClick = (e) => {
    setUrlParams({ account_info_id: row.id });
  };

  const state = sync_states[row.sync_state];

  const icons = [
    copy && { icon: mdiContentCopy, onClick: copyName, tooltip: 'Copy Name', onlyOnHover: true },
    { icon: mdiInformation, onClick: openAccountInfo, tooltip: 'Info' },
  ];

  return (
    <FormatterButton onClick={onButtonClick} className='icons-hover-trigger'>
      <div className='flex gap-2 items-center w-full relative'>
        <div className='flex items-center gap-1'>
          {state && state.show ? (
            <Tooltip placement='top' title={state.text} destroyTooltipOnHide>
              <span className='flex items-center'>
                <Icon spin={state.spin} path={state.icon} size='16px' />
              </span>
            </Tooltip>
          ) : null}
          <StatusIcon active={row.active} size={8} />
          <NetworkIcon network={row.network} size={16} />
        </div>
        <Tooltip placement='top' title={row.name} destroyTooltipOnHide mouseEnterDelay={0.1}>
          <div className='w-full overflow-hidden text-ellipsis text-left font-bold'>{row.name}</div>
        </Tooltip>

        <TableIcons color={Ninja.colors.light} icons={icons} />
      </div>
    </FormatterButton>
  );
};

const sync_states = {
  not_synced: { text: 'Not Synced', spin: false, show: true, icon: mdiDatabaseRemove },
  syncing_campaigns: { text: 'Syncing Campaigns', spin: true, show: true, icon: mdiRefresh },
  syncing_adsets: { text: 'Syncing Adsets', spin: true, show: true, icon: mdiRefresh },
  syncing_ads: { text: 'Syncing Ads', spin: true, show: true, icon: mdiRefresh },
  syncing_conversions: { text: 'Syncing Conversions', spin: true, show: true, icon: mdiRefresh },
  syncing_pixels: { text: 'Syncing Pixels', spin: true, show: true, icon: mdiRefresh },
  syncing_audiences: { text: 'Syncing Audiences', spin: true, show: true, icon: mdiRefresh },
};

export default NetworkAccountFormatter;
