import React from 'react';
import { countries } from 'components/Selects/CountriesSelect/countries';
import UrlHelper from 'common/utils/url-helper';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';

const CountryFormatter = ({ value, editable = true }) => {
  if (!value && editable) {
    return (
      <Icon
        path={mdiPencil}
        size={1}
        className='editIcon pointer bg-info-background text-info rounded-2xl p-1'
      />
    );
  }

  if (!value) {
    return <div>-</div>;
  }

  const country = countries.find(
    (country) => country.code === value.toLowerCase() || country.name == value
  );

  if (!country) {
    return <div>Country not found</div>;
  }

  return (
    <div className='flex items-center gap-2 w-full'>
      <img src={UrlHelper.getFlagIconUrl(country.code)} className='object-cover w-4' />
      <span>{country.name}</span>
      {editable && (
        <Icon
          path={mdiPencil}
          size={1}
          className='editIcon pointer bg-info-background text-info rounded-2xl p-1'
        />
      )}
    </div>
  );
};

export default CountryFormatter;

CountryFormatter.propTypes = {
  value: PropTypes.string,
};
