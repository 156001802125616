import NinjaTable from 'components/tables/NinjaTable';
import React from 'react'
import P from 'router/paths';
import DisconnectButton from 'modules/apps/components/DisconnectButton';

const columns = [
  {
    key: 'id',
    frozen: true,
    name: 'ID',
    resizable: true,
  },
  {
    key: 'name',
    frozen: true,
    name: 'Name',
    resizable: true,
  },
  {
    key: 'currency',
    frozen: true,
    name: 'Currency',
    resizable: true,
  },
  {
    key: 'domain',
    frozen: true,
    name: 'URL',
    resizable: true,
  },
  {
    key: 'disconnect',
    name: 'Diconnect',
    width: 130,
    renderCell: ({ row }) => {
      return <DisconnectButton integration='woocommerce' id={row.id} name={row.name} />;
    },
  },
];

const ConnectedWoocommerceStoresTable = (props) => {
  return (
    <NinjaTable
      title='Connected woocommerce stores'
      columns={columns}
      dataProvider='integrationList'
      queryParams={{ integration: 'woocommerce' }}
      footerProps={{ showPerPage: false }}
      disableFooter
    />
  );
};

export default ConnectedWoocommerceStoresTable;
