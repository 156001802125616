import gtagevent from '@lib/google-analytics/gtagevent';
import EventEmitter from '@ninja/core/EventEmitter';
import { amplitude } from 'tracking/trackers/amplitude';
import ire from 'tracking/trackers/ire';
import { nj, init as ninjaInit } from 'tracking/trackers/ninja';

export const ninjaEvents = new EventEmitter();

let init = false;

ninjaEvents.once('app_initialized', function (location) {
  // Init only once
  if (init) return;

  // amplitudeInit();
  ninjaInit();

  // set initialized to true
  init = true;
});

ninjaEvents.on('pageview', function ({ location, user, workspace }) {
  nj().pageview();

  ire('identify', { customerId: user?.id, customerEmail: user?.email });

  const data = {
    user_id: user?.id,
    workspace_id: workspace?.id,
    email: user?.email,
  };

  amplitude()?.track('PageView', data);
});

ninjaEvents.on('login', function (res) {
  const data = {
    user_id: res?.user_id,
    email: res?.email,
  };

  nj().event('login', data);

  amplitude()?.track('Log In', data);

  gtagevent('event', 'Login', {
    event_category: 'App',
    event_label: data.user_id,
  });
});

ninjaEvents.on('register', function (res) {
  const data = {
    user_id: res?.user_id,
    email: res?.email,
  };

  nj().event('register', data);

  amplitude()?.track('Register', data);

  gtagevent('event', 'Registration', data);

  gtagevent('event', 'Lead', {
    event_category: 'App',
    event_label: data.user_id,
  });
});

ninjaEvents.on('workspace_setup_finished', function (workspace) {
  const data = { workspace_id: workspace?.id };

  nj().event('setup_finished', data);

  amplitude()?.track('Setup Finished', data);
});

ninjaEvents.on('email_confirmed', function (workspace) {
  const data = {
    workspace_id: workspace?.id,
  };

  nj().event('email_confirmed', data);

  amplitude()?.track('Email Confirmed', data);
});

ninjaEvents.on('free_plan_selected', function (workspace) {
  const data = { workspace_id: workspace?.id };

  nj().event('free_plan_selected', data);

  amplitude()?.track('Free Plan Selected', data);
});

ninjaEvents.on('logout', function (workspace) {
  const data = { workspace_id: workspace?.id };

  nj().event('logout', data);

  amplitude()?.track('Log Out', data);
});

ninjaEvents.on('subscription_purchased', function (workspace, planCode) {
  const data = {
    plan: planCode,
    user: workspace.id,
  };

  gtagevent('event', 'Purchase', data);

  nj.event('purchase', data);

  amplitude()?.track('Subscription Purchased', data);
});

ninjaEvents.on('subscription_purchase_started', function (workspace, planCode) {
  const data = {
    plan: planCode,
    user: workspace?.id,
  };

  nj().event('subscription_purchase_started', data);

  amplitude()?.track('Subscription Purchased Started', data);
});
