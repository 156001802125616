export const antdOverride = {
  token: {
    colorPrimary: '#ff4e00',
    colorSuccess: '#91c716',
    colorWarning: '#ffbb02',
    colorError: '#eb2f20',
    colorInfo: '#2c7df0',
    colorTextBase: '#151a22',
    wireframe: false,
    fontSize: 16,
    fontFamily: 'fregular',
  },
  components: {
    Input: {
      controlHeight: 45,
      controlHeightLG: 60,
      controlHeightSM: 35,
      inputFontSizeLG: '14px',
      inputFontSizeSM: '14px ',
    },
    Select: {
      controlHeightLG: 53,
      controlHeight: 45,
      fontSizeLG: 14,
      optionFontSize: 12,
      zIndexPopup: 999999999,
    },
    DatePicker: {
      controlHeightLG: 60,
      controlHeight: 45,
    },
    Form: {
      verticalLabelPadding: '0px 5px 2px',
      itemMarginBottom: '8px',
      labelFontSize: '12px',
      fontSize: 12,
      fontWeight: 600,
    },
    Alert: {
      withDescriptionPadding: '10px 15px',
      colorWarningBg: '#FEF4E4',
      colorWarningBorder: '#FFBB02',
      colorInfoBg: '#F0F6FF',
      colorInfoBorder: '#536A8B',
      colorErrorBg: '#FBECEA',
      colorErrorBorder: '#EB2F20',
      colorSuccessBg: '#F3FAE3',
      colorSuccessBorder: '#91C716',
    },
    Menu: {
      itemSelectedBg: '#FBECEA',
      itemSelectedColor: '#ff4e00',
    },
    Tabs: {
      margin: 0,
      colorText: '#2c435a',
      itemActiveColor: '#2c435a',
      titleFontSize: 14,
      cardBg: ' rgba(0, 0, 0, 0.05)',
    },
    Button: {
      contentFontSizeSM: 12,
      contentFontSize: 14,
      contentFontSizeLG: 14,
      defaultHoverBorderColor: '#ff4e00',
      defaultHoverColor: '#ff4e00',
    },
    Checkbox: {
      fontSize: 14,
      lineWidth: 2,
      fontFamily: 'fregular',
    },
    Collapse: {
      headerBg: '#fff',
      colorBorder: '#fff',
    },
    Descriptions: {
      titleMarginBottom: '8px',
    },
    Divider: {
      marginLG: '5px',
    },
    Radio: { fontSize: 14 },
    Switch: {
      colorPrimary: 'rgb(145, 199, 22)',
    },
    Segmented: {
      itemSelectedColor: '#ff4e00',
    },
    Pagination: {
      itemActiveBg: '#FBECEA',
      fontFamily: 'fregularBold',
      fontSize: 12,
    },
    Card: {
      paddingLG: 10,
      colorSplit: '#d5d5d5',
    },
    Typography: {
      fontSizeHeading5: '12px',
      fontSizeHeading4: '14px',
    },
    Carousel: {
      arrowSize: 30,
    },
    InputNumber: {
      controlHeightLG: 53,
      fontSizeLG: 14,
      inputFontSizeSM: 12,
    },
    Popconfirm: {
      fontSize: 14,
    },
  },
};
