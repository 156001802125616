import React, { useEffect, useMemo, useState } from 'react';
import {
  mdiHandshake,
  mdiFormatListBulleted,
  mdiAccountGroup,
  mdiAccountSearch,
  mdiPlus,
  mdiFormSelect,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import P from 'router/paths';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import useCrmForms from 'modules/CRMModule/hooks/useCrmForms';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const CrmMainMenu = () => {
  const { workspace } = useWorkspace();
  const { forms } = useCrmForms();
  /**
   * @todo color unset
   */

  const items = useMemo(() => {
    return [
      {
        label: (
          <Link style={{ color: 'unset' }} to={P.CRM.CONTACTS}>
            Contacts
          </Link>
        ),
        key: 'contacts',
        icon: <Icon path={mdiAccountGroup} />,
      },
      {
        label: (
          <Link style={{ color: 'unset' }} to={P.CRM.DEALS}>
            Deals
          </Link>
        ),
        key: 'deals',
        icon: <Icon path={mdiHandshake} />,
      },
      {
        label: 'Leads',
        key: 'leads',
        icon: <Icon path={mdiFormatListBulleted} />,
        children: [
          {
            label: 'Forms',
            icon: <Icon path={mdiFormSelect} />,
            key: 'forms',
            children: forms?.length
              ? forms.map((form) => {
                  return {
                    label: (
                      <Link style={{ color: 'unset' }} to={P.CRM.DATA_LIST.replace(':id', form.id)}>
                        {form.name} - {form.leads_count} Leads
                      </Link>
                    ),
                    icon: <Icon path={mdiAccountSearch} />,
                    key: form.id + ':list',
                  };
                })
              : [
                  {
                    label: 'No Forms',
                    key: 'no_forms',
                    disabled: true,
                  },
                ],
          },
          {
            label: (
              <Link style={{ color: 'unset' }} to={P.CRM.INDEX}>
                Create New Form
              </Link>
            ),
            key: 'create_form',
            icon: <Icon path={mdiPlus} />,
          },
        ],
      },
    ];
  }, [workspace.crm_form_id, forms]);

  const [selected, setSelected] = useState();
  const history = useHistory();
  const location = useLocation();

  const findItemByPath = (items, path) => {
    for (const item of items) {
      if (item.children) {
        const found = findItemByPath(item.children, path);
        if (found) {
          return found;
        }
      }
      if (item.label?.props?.to === path) {
        return item;
      }
    }
    return null;
  };

  useEffect(() => {
    const selectedItem = findItemByPath(items, location.pathname);
    if (selectedItem) {
      setSelected(selectedItem.key);
    } else {
      setSelected('leads');
    }
  }, [location.pathname, items]);

  const onSelect = ({ key }) => {
    const findItemByKey = (items, key) => {
      for (const item of items) {
        if (item.key === key) {
          return item;
        }
        if (item.children) {
          const found = findItemByKey(item.children, key);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const selectedItem = findItemByKey(items, key);
    if (selectedItem) {
      setSelected(key);
      const url = selectedItem.url;
      if (url) {
        history.push(url);
      }
    }
  };

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
    </div>
  );
};

export default CrmMainMenu;
