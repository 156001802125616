import { Button } from '@material-ui/core';
import { mdiFileSearch, mdiFolder, mdiMinus, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import MultipleSelect from 'components/form/fields/MultipleSelect';
import Select from 'components/form/fields/Select';
import TextField from 'components/form/fields/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useFileManagerToolbar,
  useFileMangerFolderCreationFormOpened,
} from 'store/redux/states/filemanager/filemanager.hooks';
import FolderCreationForm from './FolderCreationForm';

const FileManagerToolbar = (props) => {
  const [toolbar, setToolbar] = useFileManagerToolbar();
  const [folderCreationFormOpened, setFolderCreationFormOpened] =
    useFileMangerFolderCreationFormOpened();

  const handleChange = (e, name) => {
    const field = name ? name : e.target?.name;
    const value = name ? e : e.target?.value;

    setToolbar({ [field]: value });
  };

  return (
    <div className='flex flex-col gap-2 '>
      <div className='flex-center gap-2'>
        <Button
          className='flex-grow'
          width='100px'
          onClick={() => setFolderCreationFormOpened(!folderCreationFormOpened)}
        >
          <Icon path={folderCreationFormOpened ? mdiMinus : mdiPlus} />
          <Icon path={mdiFolder} />
        </Button>
        <MultipleSelect
          label='Type'
          value={toolbar.types}
          name='types'
          options={{
            video: { name: 'Video', value: 'video' },
            image: { name: 'Image', value: 'image' },
            document: { name: 'Document', value: 'document' },
          }}
          onChange={handleChange}
          margin='dense'
        />
        <Select
          label='Sort By'
          value={toolbar.order_by}
          name='order_by'
          options={{
            date: { name: 'Date', value: 'date' },
            size: { name: 'Size', value: 'size' },
            type: { name: 'Type', value: 'type' },
          }}
          onChange={handleChange}
          margin='dense'
        />
        <TextField
          label='Search'
          name='search'
          defaultValue={toolbar.search}
          endIcon={mdiFileSearch}
          onChange={handleChange}
          variant='outlined'
          margin='dense'
        />
      </div>
      <FolderCreationForm />
    </div>
  );
};

FileManagerToolbar.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FileManagerToolbar;
