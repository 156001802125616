import React from 'react';
import { mdiTrashCanOutline } from '@mdi/js';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { snackbar } from 'components/notifications/notifications';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const DeleteFormatter = ({ param = 'id', row, deleteRow }) => {
  const handleDelete = () => {
    deleteRow({ [param]: row.id })
      .unwrap()
      .then(() => {
        snackbar.success('Deleted successfully');
      })
      .catch((error) => {
        const errorMessage = error?.message || 'Delete failed';
        snackbar.error(errorMessage);
      });
  };

  return (
    <Popconfirm
      icon={<img src='/icons/cross/warning.svg' alt='warning' />}
      className='!text-sm flex items-center justify-center w-full'
      placement='left'
      description='Are you sure you want to delete this?'
      okText='Yes'
      cancelText='No'
      onConfirm={handleDelete}
    >
      <TableIcons color={Ninja.colors.light} icons={[{ icon: mdiTrashCanOutline, size: 0.8 }]} />
    </Popconfirm>
  );
};

export default DeleteFormatter;

DeleteFormatter.propTypes = {
  row: PropTypes.object,
  deleteRow: PropTypes.func,
  param: PropTypes.string,
};
