import ninjaApi from 'store/redux/apis/ninja.api';

export const dashboardApi = ninjaApi.injectEndpoints({
  tagTypes: ['Dashboard'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Load Daily Stats
     */
    dashboardDailyStats: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/daily',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
    /**
     * Load Worst Ads
     */
    dashboardWorstAds: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/worst',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
    /**
     * Load Best Ads Widget
     */
    dashboardBestAds: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/best',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
    /**
     * Load Dashboard budgets widget
     */
    dashboardBudgets: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/budgets',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
    /**
     * load dashboard total data
     */
    dashboardTotals: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/totals',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
    /**
     * load dashboard total sales data
     */
    dashboardTotalSales: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/totalSales',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
    dashboardGet: builder.query({
      query: (body) => ({
        url: '/workspace/dashboard/get',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),

    dashboardSave: builder.mutation({
      query: (body) => ({
        url: '/workspace/dashboard/save',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),

    networksTotalStatistics: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/network_reports',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),

    //networks reports data
    networksReports: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/grouped-network-reports',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),

    /**
 * Load PnL by channels
 */
    channelsProfitAndLoss: builder.query({
      query: (body) => ({
        url: '/dashboard/widgets/networks',
        method: 'POST',
        body,
      }),
      providesTags: ['Dashboard'],
    }),
  }),
});

export const {
  useDashboardDailyStatsQuery,
  useDashboardWorstAdsQuery,
  useDashboardBestAdsQuery,
  useDashboardBudgetsQuery,
  useDashboardTotalsQuery,
  useDashboardGetQuery,
  useDashboardSaveMutation,
  useStatsByAdAccountQuery,
  useNetworksReportsQuery,
  useDashboardTotalSalesQuery,
  useChannelsProfitAndLossQuery
} = dashboardApi;
