import React from 'react';
import { Modal } from 'antd';
import { AlertConfig } from '../const';
import './confirmModal.css';

const iconMap = {
    warning: <img src={AlertConfig.warning.icon} alt='Warning' />,
    info: <img src={AlertConfig.info.icon} alt='Information' />,
    success: <img src={AlertConfig.success.icon} alt='Success' />,
    error: <img src={AlertConfig.error.icon} alt='Error' />,
};

const ConfirmModal = ({
    title,
    content,
    iconType = 'warning',
    onCancel,
    centered = true,
    ...rest
}) => {
    return Modal.confirm({
        title,
        content,
        icon: iconMap[iconType] || iconMap['warning'],
        onCancel,
        centered,
        ...rest,
    });
};

export default ConfirmModal;