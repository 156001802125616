import { Chip, ListItemText, MenuItem } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import Ninja from '@ninja';
import { makeStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import Checkbox from './Checkbox';
import Select from './Select';

const onChipMouseDown = (event) => {
  event.stopPropagation();
};

export const Option = ({ name, checked = false, checkbox = false }) => {
  return (
    <Fragment>
      {checkbox && <Checkbox checked={checked} />}
      <ListItemText primary={name} />
    </Fragment>
  );
};

const MultipleSelect = (props) => {
  const classes = useStyles();
  const {
    value = [],
    chips = false,
    checked,
    options: baseOptions = [],
    onChange = null,
    placeholder,
    checkbox,
    skip = [],
    ...rest
  } = props;
  const options = Ninja.normalize(baseOptions);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const handleChange = (values) => {
    onChange && onChange(values, rest.name);
    if (Object.values(options).length > values.length) {
      setIsCheckedAll(false);
    } else {
      setIsCheckedAll(true);
    }
  };

  const getSelectedObjects = () => {
    if (!Array.isArray(value)) return [];

    return value.map((v) => {
      return options[v];
    });
  };

  const deleteByValue = (key) => {
    const val = [...value];
    handleChange([...val.toggle(key)]);
  };

  const renderValue = () => {
    const selected = getSelectedObjects();
    if (!selected.length) return placeholderMessage();

    if (chips) {
      return (
        <Fragment>
          {(selected || []).map((value) => {
            return (
              value && (
                <Chip
                  className={classes.root}
                  clickable
                  onMouseDown={onChipMouseDown}
                  onDelete={() => deleteByValue(value.value)}
                  deleteIcon={<Icon path={mdiClose} />}
                  key={value?.value}
                  label={value?.name}
                />
              )
            );
          })}
        </Fragment>
      );
    }

    return (selected || [])
      .filter((s) => s)
      .map((value) => value.name)
      .join(', ');
  };

  const placeholderMessage = () => {
    return <em>{placeholder}</em>;
  };

  const toggleChecked = (e) => {
    const checked = e.target.checked;
    if (checked) {
      handleChange(Object.values(options).map((opt) => opt.value));
    } else {
      handleChange([]);
    }
  };

  const renderOption = (key) => {
    const opt = options[key];
    const checked = value.includes(opt.value);

    if (skip.includes(key)) return null;
    return (
      <MenuItem disableGutters value={opt.value}>
        <Option value={opt.value} name={opt.name} checkbox={checkbox} checked={checked} />
      </MenuItem>
    );
  };

  return (
    <Select
      multiple
      renderOption={renderOption}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      renderValue={renderValue}
      options={options}
      checkedAll={toggleChecked}
      checked={isCheckedAll}
      {...rest}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: { background: '#F1F5F8', fontWeight: 400 },
}));

export default MultipleSelect;
