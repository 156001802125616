import React, { useState } from 'react';
import { Typography, Popover, Flex } from 'antd';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight } from '@mdi/js';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const StatusPopoverFormatter = (props) => {
  const { onChange, value, options } = props;
  const [open, setOpen] = useState(false);

  const selected = options.find((op) => op.id === value);

  const handleOpenChange = () => {
    setOpen(!open);
  };

  const convertHexToRgba = (hex, opacity = 0.1) => {
    const cleanHex = hex.replace('#', '');
    const bigint = parseInt(cleanHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const icons = [
    { icon: open ? mdiChevronRight : mdiChevronDown, size: 0.7, onClick: handleOpenChange },
  ];

  return (
    <Popover
      content={
        <div className='max-h-96 overflow-auto min-w-48'>
          {options.map((option) => (
            <div
              onClick={() => {
                onChange?.(option.id), setOpen(!open);
              }}
              key={option.id}
              value={option.id}
              className='cursor-pointer p-2 hover:bg-background-light rounded-md'
            >
              <div className='!flex !items-center gap-1 py-1'>
                <div
                  className='w-2 h-2 rounded-full'
                  style={{
                    background: option.color,
                    marginRight: 8,
                  }}
                />
                <span className='text-sm font-normal' style={{ color: option.color }}>
                  {option.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      }
      trigger='click'
      open={open}
      placement='rightBottom'
      onOpenChange={handleOpenChange}
      className='cursor-pointer'
    >
      {selected ? (
        <div
          className='absolute right-0 w-full h-full flex items-center justify-between p-2 border pl-4'
          style={{ background: selected.color ? convertHexToRgba(selected?.color, 0.1) : 'white' }}
        >
          <div className='flex items-center gap-2 w-full justify-between relative'>
            <Flex align='center' gap='small'>
              <div
                style={{
                  backgroundColor: selected?.color,
                }}
                className='w-3 h-3 rounded-full'
              />
              <Typography className='text-xs font-semibold' style={{ color: selected?.color }}>
                {selected.name}
              </Typography>
            </Flex>
            <TableIcons color={Ninja.colors.light} icons={icons} />
          </div>
        </div>
      ) : (
        <div className='relative w-full flex items-center justify-between'>
          <Typography className='text-xs font-normal text-info'>No Status</Typography>
          <Icon
            path={open ? mdiChevronRight : mdiChevronDown}
            size={0.7}
            className='text-info absolute right-0'
          />
        </div>
      )}
    </Popover>
  );
};

export default StatusPopoverFormatter;

StatusPopoverFormatter.propTypes = {
  value: PropTypes.number,
};
