import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { mdiCloudUpload, mdiDelete, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import useFileManagerConfig from '@ninja/hooks/useFileManagerConfig';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileManagerFiles from './FileManagerFiles';
import { useFileManagerCurrentFolder } from 'store/redux/states/filemanager/filemanager.hooks';
import { Alert } from '@material-ui/lab';
import useFileUpload from '../hooks/useFileUpload';
import bytesToHuman from '../utils/bytesToHuman';

const FileManagerFileUploader = (props) => {
  const classes = useStyles();
  const [stagedFiles, setStagedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const { config, loading: configLoading } = useFileManagerConfig();
  const [error, setError] = useState(false);
  const [currentFolder] = useFileManagerCurrentFolder();
  const { upload, progress, uploadedBytes, fileSize, uploadInProgress } = useFileUpload({
    onUploadSuccess: () => {
      onClear();
    },
    onUploaderror: () => {
      setError(true);
    },
  });

  const onDrop = (acceptedFiles) => {
    const new_files = [];
    const new_filenames = [];
    acceptedFiles.forEach((file) => {
      if (selectedFileNames.includes(file.name)) return;
      new_files.push({
        uploaded_file: file,
        thumbnail: URL.createObjectURL(file),
        id: file.name,
        name: file.name,
        size: file.size,
        type:
          file.type.indexOf('image') > -1
            ? 'image'
            : file.type.indexOf('video') > -1
            ? 'video'
            : 'file',
      });
      new_filenames.push(file.name);
    });

    setStagedFiles([...stagedFiles, ...new_files]);
    setSelectedFileNames([...selectedFileNames, ...new_filenames]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: ['image/*', 'video/*'],
    maxFiles: config.max_files || 10,
    maxSize: Math.min(config.max_size, config.remaining_space) || 50000,
  });

  const onFileSelect = (fileName) => {
    setSelectedFileNames([...selectedFileNames.toggle(fileName)]);
  };

  const onClear = () => {
    setSelectedFileNames([]);
    setStagedFiles([]);
  };

  const onUpload = () => {
    // filter staged files and return staged files

    const files = stagedFiles
      // filter files that has uploadedfile in it
      .filter((file) => {
        return selectedFileNames.includes(file.name) && typeof file.uploaded_file !== 'undefined';
      })
      .map((f) => f.uploaded_file);

    upload(files, currentFolder);
  };

  if (configLoading) {
    return (
      <div className='flex-center'>
        <CircularProgress size='3rem' />
      </div>
    );
  }

  return (
    <div className='overflow-auto max-h-[400px]'>
      <input {...getInputProps()} />
      <div
        className={clsx(classes.container, 'flex-center', 'flex-col')}
        {...getRootProps({ refKey: 'innerRef' })}
      >
        <Typography className='flex-center' variant='h5' color='secondary'>
          <Icon path={mdiCloudUpload} />
          &nbsp; Choose a file or drag it here
        </Typography>
        <Typography className='flex-center' variant='caption' color='secondary'>
          Disk Space Remaining: {config.remaining_mb} MB
        </Typography>
      </div>
      <Collapse collapsedSize={0} in={stagedFiles.length}>
        <div className='grid gap-4 py-4'>
          {uploadInProgress ? <LinearProgress color='secondary' /> : null}
          <FileManagerFiles
            height='unset'
            selected={selectedFileNames}
            onFileSelect={onFileSelect}
            files={stagedFiles}
          />
          {error ? (
            <Alert severity='error'>Error while uploading {stagedFiles.length} files</Alert>
          ) : null}
          {uploadInProgress ? (
            <div className='flex flex-center gap-2'>
              <span>{bytesToHuman(uploadedBytes, 'MB')}</span>
              <div className='flex flex-grow'>
                <LinearProgress className='w-full' value={progress} variant='determinate' />
              </div>
              <span>{bytesToHuman(fileSize, 'MB')}</span>
            </div>
          ) : null}
          <div className='flex gap-4'>
            <Button
              disabled={uploadInProgress}
              startIcon={<Icon path={mdiDelete} />}
              onClick={onClear}
              width='100%'
              size='large'
              color='info'
            >
              Clear
            </Button>
            <Button
              startIcon={<Icon path={mdiUpload} />}
              disabled={uploadInProgress || selectedFileNames.length === 0}
              width='100%'
              size='large'
              onClick={onUpload}
            >
              Upload {selectedFileNames.length} Files
            </Button>
          </div>
          <Divider />
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px dashed ${theme.palette.secondary.main}`,
    height: '4rem',
    borderRadius: '6px',
  },
}));

export default FileManagerFileUploader;
