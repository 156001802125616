import { mdiFolder } from '@mdi/js';
import RightDrawer from 'components/panels/RightDrawer';
import React, { useState } from 'react';
import FileManagerFiles from './components/FileManagerFiles';
import FileManagerFileUploader from './components/FileManagerFileUploader';
import FileManagerSelectedFilesInfo from './components/FileManagerSelectedFilesInfo';
import FileManagerToolbar from './components/FileManagerToolbar';
import {
  useDeleteFilesMutation,
  useGetFilesQuery,
} from 'store/redux/states/filemanager/filemanager.api';
import { Collapse, LinearProgress } from '@material-ui/core';
import {
  useFileManagerCurrentFolder,
  useFileManagerToolbar,
  useImageToCrop,
} from 'store/redux/states/filemanager/filemanager.hooks';
import Dialog from 'components/Dialogs/Dialog';
import { AlertType } from 'components/Dialogs/const';
import FileManagerCropper from './FileManagerCropper';

const FileManager = (props) => {
  const {
    multiple = true,
    filetypes = null,
    selected: initialSelected = [],
    onChoose = () => {},
    onCroppedUpload,
    ...rest
  } = props;
  const [toolbar] = useFileManagerToolbar();
  const { image_to_crop, cropper_title, setImageToCrop } = useImageToCrop();
  const [selectedFileIds, setSelectedFileIds] = useState([...initialSelected]);
  const [currentFolder, setCurrentFolder] = useFileManagerCurrentFolder();
  const {
    data: { data: files, meta } = { data: [], meta: {} },
    isLoading,
    isFetching,
  } = useGetFilesQuery({
    folder: currentFolder,
    filetypes: filetypes,
    ...toolbar,
  });
  const [deleteFilesQuery, { isLoading: isDeleteLoading }] = useDeleteFilesMutation();

  /**
   * When user clicks on image or floder
   * @param {String|Number} id
   */
  const onFileSelect = (id) => {
    // Get file from state
    const file = files.find((f) => f.id == id);

    if (typeof file !== 'object') return;

    // If selected file is folder
    // Navigate to selected folder and clear already selected files
    if (file.is_folder) {
      setCurrentFolder(file.thumbnail);
      setSelectedFileIds([]);
    } else if (multiple) {
      //  If multiple files are allowed append or delete id from selecteds
      setSelectedFileIds([...selectedFileIds.toggle(id)]);
    } else {
      // If multiple files are not allowed set selected only current id
      setSelectedFileIds([id]);
    }
  };

  /**
   * When use icon is clicked on footer
   * Fire onchoose function received from choosing component
   * Clear selected
   */
  const onUseClicked = () => {
    onChoose(selectedFileIds, getFilesByIds(selectedFileIds));
    setSelectedFileIds([]);
  };

  /**
   * Get files data by given file ids
   * @param {Array} ids
   * @returns {Array}
   */
  const getFilesByIds = (ids) => {
    return files.filter((f) => ids.includes(f.id));
  };

  /**
   * Triggered when crop requested
   */
  const onCropClicked = () => {
    if (selectedFileIds?.length !== 1) return;
    const id_to_crop = selectedFileIds[0];
    const file = files.find((f) => f.id == id_to_crop);
    if (!file) return;

    setImageToCrop(file);
  };

  /**
   * In select clear, When trash icon is clicked
   */
  const onDelete = () => {
    window.alert(
      'Are you sure you want to delete this files',
      () => {
        deleteFilesQuery({ ids: selectedFileIds })
          .unwrap()
          .then(() => {
            setSelectedFileIds([]);
          });
      },
      {
        type: AlertType.Warning,
      }
    );
  };

  const loading = isLoading || isFetching;

  const getFolders = () => {
    const folders = [{ name: 'root', path: '/' }];
    const paths = currentFolder.split('/').filter((p) => Boolean(p.trim().length));
    let currentPath = '';

    paths.forEach((p) => {
      currentPath += '/' + p;
      folders.push({
        name: p,
        path: currentPath,
      });
    });

    return folders;
  };

  return (
    <RightDrawer
      headerProps={{
        className: 'space-x-2',
        primary: (
          <div className='flex items-center gap-2'>
            File Manager:
            <div className='flex text-blue gap-1'>
              {getFolders().map((f) => {
                return (
                  <div
                    onClick={() => {
                      setCurrentFolder(f.path);
                    }}
                    className='hover:underline cursor-pointer'
                  >
                    {' '}
                    / {f.name}
                  </div>
                );
              })}
            </div>
          </div>
        ),
        iconPath: mdiFolder,
        iconProps: { color: 'secondary' },
      }}
      containerClassName=' relative overflow-hidden'
      {...rest}
    >
      <div className='h-full grid gap-4 p-4' style={{ gridTemplateRows: 'auto auto 1fr' }}>
        <div className='grid gap-4'>
          {loading ? <LinearProgress /> : null}
          <FileManagerToolbar />
          <FileManagerFileUploader />
        </div>

        <Collapse in={Boolean(selectedFileIds.length)}>
          <FileManagerSelectedFilesInfo
            totalFiles={meta.total}
            selected={selectedFileIds}
            onUseClicked={onUseClicked}
            onCropClicked={onCropClicked}
            onDelete={onDelete}
          />
        </Collapse>

        <FileManagerFiles selected={selectedFileIds} onFileSelect={onFileSelect} files={files} />
      </div>
      <Dialog title={cropper_title} open={Boolean(image_to_crop?.src)}>
        <FileManagerCropper onUpload={onCroppedUpload} />
      </Dialog>
    </RightDrawer>
  );
};

export default FileManager;

/**
 * @todo connect this to redux
 */
