import ninjaApi from 'store/redux/apis/ninja.api';

export const appsApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Shopify', 'Woocommerce', 'NetworkAccount', 'Integrations'],
  endpoints: (build) => ({
    disconnectIntegration: build.mutation({
      query: (data) => ({
        url: '/apps/integration/disconnect',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Shopify', 'Woocommerce', 'NetworkAccount', 'Integrations'],
    }),
    integrationList: build.query({
      query: (data) => ({
        url: '/integration/list',
        method: 'POST',
        data,
      }),
      providesTags: ['Integrations'],
    }),
  }),
});

export const { useIntegrationListQuery, useDisconnectIntegrationMutation } = appsApi;
