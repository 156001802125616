/**
 * Create chunks from given files
 * @param {File} file
 * @param {number} chunk_size
 * @returns {Array}
 */
export default function chunkFile(file, chunk_size = 2048 * 1000) {
  let size = chunk_size,
    chunks_quantity = Math.ceil(file.size / size),
    chunks = [];

  // Create chunk size and make array from it
  for (let i = 0; i < chunks_quantity; i++) {
    chunks.push(file.slice(i * size, Math.min(i * size + size, file.size), file.type));
  }

  return chunks;
}
