import { Button, ButtonGroup, Divider, IconButton, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { CircleStencil, Cropper, RectangleStencil } from 'react-advanced-cropper';
import {
  useFileManagerCurrentFolder,
  useImageToCrop,
} from 'store/redux/states/filemanager/filemanager.hooks';
import 'react-advanced-cropper/dist/style.css';
import Icon from '@mdi/react';
import {
  mdiArrowCollapseAll,
  mdiArrowExpandAll,
  mdiArrowExpandHorizontal,
  mdiArrowExpandVertical,
  mdiCircle,
  mdiFlipHorizontal,
  mdiFlipVertical,
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiRectangle,
  mdiRotateLeft,
  mdiRotateRight,
  mdiSquare,
  mdiSquareOutline,
} from '@mdi/js';
import { useUploadFilesMutation } from 'store/redux/states/filemanager/filemanager.api';
import useFileUpload from './hooks/useFileUpload';

export default function FileManagerCropper(props) {
  const { showCrop = true, onUpload } = props;

  /** @var {CropperRef} */
  const cropperRef = useRef(null);
  const [uploadImageQuery, { isLoading: isUploadLoading }] = useUploadFilesMutation();
  const [currentFolder] = useFileManagerCurrentFolder();
  const {
    image_to_crop,
    cropper_helper_text,
    crop_aspect_ratio,
    setImageToCrop,
    setAspectRatio,
    setCroppedImage,
  } = useImageToCrop();
  const [circle, setCircle] = useState(false);
  const { upload, progress, uploadedBytes, fileSize, uploadInProgress } = useFileUpload({
    onUploadSuccess: () => {
      console.log('success');
    },
    onUploaderror: () => {
      console.log('error');
    },
  });

  const onChange = (cropper) => {
    // console.log(cropper.getCoordinates());
  };

  const center = () => {
    cropperRef.current?.setCoordinates(
      ({ coordinates, imageSize }) =>
        coordinates && {
          left: imageSize.width / 2 - coordinates.width / 2,
          top: imageSize.height / 2 - coordinates.height / 2,
        }
    );
  };

  const expand = (horizontal, vertical) => () => {
    cropperRef.current?.setCoordinates(({ coordinates, imageSize }) => {
      if (!coordinates) return undefined;
      if (horizontal) {
        coordinates.left = 0;
        coordinates.width = imageSize.width;
      }

      if (vertical) {
        coordinates.top = 0;
        coordinates.bottom = imageSize.height;
        coordinates.height = imageSize.height;
      }

      return coordinates;
    });
  };

  const zoom = (zoom) => () => {
    cropperRef.current?.zoomImage(zoom);
  };

  const flip = (horizontal, vertical) => () => {
    cropperRef.current?.flipImage(horizontal, vertical);
  };

  const rotate = (angle) => () => {
    cropperRef.current?.rotateImage(angle);
  };

  const saveImage = (data = null) => {
    if (!data) {
      data = new FormData();
    }
    // Add current folder paths
    data.append('path', currentFolder);
    cropperRef.current?.getCanvas()?.toBlob((image) => {
      upload(image, '/');
      return;
    }, 'image/png');
  };

  const onClose = () => {
    setImageToCrop(null);
  };

  if (!image_to_crop) return null;

  const aspectRatio = crop_aspect_ratio || 16 / 9;

  return (
    <div className='flex flex-col gap-4'>
      {cropper_helper_text && <Typography variant='body2'>{cropper_helper_text}</Typography>}
      <div className='flex gap-4'>
        <ButtonGroup color='secondary' variant='filled' orientation='vertical'>
          <IconButton>
            <Icon path={mdiMagnifyPlus} onClick={zoom(1.5)} />
          </IconButton>
          <IconButton>
            <Icon path={mdiMagnifyMinus} onClick={zoom(0.8)} />
          </IconButton>
          <Divider />
          <IconButton>
            <Icon path={mdiRotateLeft} onClick={rotate(-90)} />
          </IconButton>
          <IconButton>
            <Icon path={mdiRotateRight} onClick={rotate(90)} />
          </IconButton>
          <Divider />
          <IconButton>
            <Icon path={mdiFlipHorizontal} onClick={flip(true, false)} />
          </IconButton>
          <IconButton>
            <Icon path={mdiFlipVertical} onClick={flip(false, true)} />
          </IconButton>
        </ButtonGroup>
        <Cropper
          ref={cropperRef}
          src={image_to_crop.src}
          onChange={onChange}
          className='cropper'
          stencilComponent={circle ? CircleStencil : RectangleStencil}
          stencilProps={{
            aspectRatio: aspectRatio,
          }}
        />
        <ButtonGroup color='secondary' orientation='vertical'>
          <IconButton>
            <Icon
              path={mdiSquare}
              onClick={() => {
                setCircle(false);
                setAspectRatio(1);
              }}
            />
          </IconButton>
          <IconButton>
            <Icon
              path={mdiRectangle}
              onClick={() => {
                setCircle(false);
                setAspectRatio(16 / 9);
              }}
            />
          </IconButton>
          <IconButton>
            <Icon
              path={mdiSquareOutline}
              onClick={() => {
                setCircle(false);
                setAspectRatio(4 / 3);
              }}
            />
          </IconButton>
          <IconButton>
            <Icon path={mdiCircle} onClick={() => setCircle(true)} />
          </IconButton>
          <Divider />
          <IconButton>
            <Icon path={mdiArrowExpandHorizontal} onClick={expand(true, false)} />
          </IconButton>
          <IconButton>
            <Icon path={mdiArrowExpandVertical} onClick={expand(false, true)} />
          </IconButton>
          <IconButton>
            <Icon path={mdiArrowExpandAll} onClick={expand(true, true)} />
          </IconButton>
          <IconButton>
            <Icon path={mdiArrowCollapseAll} onClick={center} />
          </IconButton>
        </ButtonGroup>
      </div>
      <div className='flex w-full justify-between'>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>

        <ButtonGroup>
          {showCrop ? (
            <Button disabled={isUploadLoading} onClick={() => saveImage()}>
              Save as copy
            </Button>
          ) : null}
        </ButtonGroup>
      </div>
    </div>
  );
}
