import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCheckWoocommerceStoreUrlMutation } from 'modules/apps/apps/WoocommerceApp/api/woocommerce.api';
import { debounce } from 'lodash';

/**
 * Checks woocommerce store url if woocommerce and mydataninja is installed
 * @returns {{
 * check: {(url: string): Promise<CheckWoocommerceUrlResult>},
 * isChecked: boolean,
 * isWoocommerceInstalled: boolean,
 * isMyDataNinjaInstalled: boolean,
 * canConnect: boolean,
 * woocommerceInstallUrl: string,
 * mydataninjaIntallUrl: string,
 * isLoading: boolean,
 * canCheckAgain: boolean,
 * }}
 */
const useWoocommerceDomainCheck = () => {
  const [checkStoreUrlQuery, { isLoading, data = null, isUninitialized }] =
    useCheckWoocommerceStoreUrlMutation();

  const check = useCallback(
    debounce((url) => {
      return checkStoreUrlQuery({
        url: url,
      }).unwrap();
    }, 500),
    []
  );

  return {
    check,
    isChecked: data !== null,
    canCheckAgain: !isUninitialized && !isLoading && !data?.can_connect,
    isWoocommerceInstalled: data?.woocommerce,
    isMyDataNinjaInstalled: data?.mydataninja,
    canConnect: data !== null && data?.can_connect,
    woocommerceInstallUrl: String(data?.woocommerce_install_url),
    mydataninjaIntallUrl: String(data?.mydataninja_install_url),
    isLoading,
  };
};

useWoocommerceDomainCheck.propTypes = {};

export default useWoocommerceDomainCheck;

/**
 * @typedef CheckWoocommerceUrlResult
 * @property {Boolean} woocommerce
 * @property {Boolean} mydataninja
 * @property {Boolean} can_connect
 * @property {String} woocommerce_install_url
 * @property {String} mydataninja_install_url
 */
