import React from 'react';
import { Tooltip, Flex, Typography } from 'antd';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';

const AdUrlFormmatter = ({ data }) => {
  if (!data?.id) {
    return null;
  }

  return (
    <div className='flex items-center bg-info-background p-1.5 rounded-xl font-normal min-w-48 flex-nowrap truncate text-ellipsis'>
      <Tooltip
        placement='top'
        destroyTooltipOnHide
        mouseEnterDelay={0.1}
        title={
          <Flex gap='small' align='start' vertical className='text-sm'>
            {data?.id && (
              <Flex justify='center' align='center' gap='small'>
                <Typography className='font-normal text-info-background '>Ad:</Typography>
                <span className='bg-info-background text-color-black font-normal rounded-2xl p-1 px-2'>
                  {data?.name || '-'}
                </span>
              </Flex>
            )}
            {data?.adset?.id && (
              <Flex justify='center' align='center' gap='small'>
                <Typography className='font-normal text-info-background '>Adset:</Typography>
                <span className='bg-info-background text-color-black font-normal rounded-2xl p-1 px-2'>
                  {data?.adset?.name}
                </span>
              </Flex>
            )}
            {data?.campaign?.id && (
              <Flex justify='center' align='center' gap='small'>
                <Typography className='font-normal text-info-background '>Campaign:</Typography>
                <span className='bg-info-background text-color-black rounded-2xl p-1 px-2'>
                  {data?.campaign?.campaign}
                </span>
              </Flex>
            )}
          </Flex>
        }
      >
        <Icon path={mdiInformationOutline} size={1.1} className='cursor-pointer rounded-2xl p-1' />
      </Tooltip>
      <span className='text-xs font-medium  flex-nowrap truncate text-ellipsis relative'>
        {data.name}
      </span>
    </div>
  );
};

export default AdUrlFormmatter;

AdUrlFormmatter.propTypes = {
  data: PropTypes.object,
};
