import React, { useState } from 'react';
import CampaignDetailsPopover from 'components/Popovers/CampaignDetailsPopover';
import { mdiContentCopy, mdiFilterVariant } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';
import { useModal } from 'store/redux/states/ui/ui.hooks';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import {
  TrackingStateFormatter,
  StatusFormatter,
  NameFormatter,
  NetworkFormatter,
  NotTrackingFormatter,
  IconTypeFormatter,
} from 'components/tables/NinjaTable/formatters';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import copyToClipboard from 'common/utils/copyToClipboard';
import AdProcessFormatter from './AdProcessFormatter';

const CampaignFormatter = ({ row, showNetwork = true, editStatus = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [, setModal] = useModal('campaign_copy');
  const { setUrlParams } = useQueryParams();
  const history = useHistory();

  if (!row || !row.campaign) {
    return 'No Campaign';
  }

  const { campaign } = row;

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    history.push(P.REPORTING.INDEX);
    setUrlParams({ group_by: 'adset', adset_ids: row.adset.id });
  };

  const duplicateCampaign = () => {
    setModal({ campaign_id: campaign.id });
  };

  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(campaign.name);
  };

  const icons = [
    {
      icon: mdiContentCopy,
      onClick: copyName,
      tooltip: 'Copy Name',
      onlyOnHover: true,
      show: campaign?.name,
    },
    // {
    //   icon: mdiContentCopy,
    //   onClick: duplicateCampaign,
    //   show: campaign.network === 'facebook',
    //   tooltip: 'Duplicate Campaign',
    // },
    {
      icon: mdiFilterVariant,
      onClick: handleChange,
      tooltip: 'Reports',
      show: row?.adset?.id ? true : false,
    },
  ];

  return (
    <FormatterButton className='icons-hover-trigger'>
      <div className='flex gap-1 items-center w-full relative'>
        <div className='flex items-center gap-1'>
          {editStatus ? (
            <AdProcessFormatter row={campaign} level='campaign' />
          ) : (
            <StatusFormatter row={campaign} />
          )}
          {showNetwork && <NetworkFormatter row={campaign} />}
          <NotTrackingFormatter data={campaign} />
          <TrackingStateFormatter isTracked={campaign?.is_tracked} />
        </div>
        <NameFormatter name={campaign.name} />
        <TableIcons color={Ninja.colors.light} icons={icons} />
        <IconTypeFormatter row={campaign} size={0.7} />
        <CampaignDetailsPopover
          id={campaign.id}
          open={open}
          onClose={handlePopoverClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </div>
    </FormatterButton>
  );
};

export default CampaignFormatter;
