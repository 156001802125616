import api from 'common/api';

class AdsetService {
  static createAdset(data) {
    return api.call('post', '/network/adset/create', data).then(api.getData);
  }

  static sync(data) {
    return api.call('post', '/network/adset/sync', data).then(api.getData);
  }

  static autoComplete(data) {
    return api.call('post', '/network/adset/autocomplete', data).then(api.getData);
  }

  static search(data) {
    return api.call('post', '/network/adset/search', data).then(api.getData);
  }

  static getPreviews(id) {
    return api.call('post', '/network/adset/ad-previews', { adset_id: id }).then(api.getData);
  }

  static changeStatus(data) {
    return api.call('post', '/network/adset/change-status', data).then(api.getData);
  }
}

export default AdsetService;
