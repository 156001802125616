import { Button, Collapse } from '@material-ui/core';
import { mdiFolder } from '@mdi/js';
import TextField from 'components/form/fields/TextField';
import React, { useState } from 'react';
import { useCreateFolderMutation } from 'store/redux/states/filemanager/filemanager.api';
import {
  useFileManagerCurrentFolder,
  useFileMangerFolderCreationFormOpened,
} from 'store/redux/states/filemanager/filemanager.hooks';

const FolderCreationForm = () => {
  const [folderName, setFolderName] = useState('');
  const [error, setError] = useState(false);

  const [createFolder] = useCreateFolderMutation();
  const [currentFolder] = useFileManagerCurrentFolder();
  const [folderCreationFormOpened, setFolderCreationFormOpened] =
    useFileMangerFolderCreationFormOpened();

  const handleCreateFolder = () => {
    setError(false);
    createFolder({
      name: folderName,
      path: currentFolder,
    })
      .unwrap()
      .then(() => {
        setFolderCreationFormOpened(false);
        setFolderName('');
      })
      .catch(() => {
        setError(true);
      });
  };

  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };

  return (
    <Collapse component='div' in={folderCreationFormOpened} collapsedSize={0}>
      <div className='flex gap-2'>
        <TextField
          placeholder='Enter Folder Name'
          name='folder_name'
          className='flex-grow'
          startIcon={mdiFolder}
          value={folderName}
          onChange={handleFolderNameChange}
          error={error}
          variant='outlined'
          margin='dense'
        />
        <Button width='100px' onClick={handleCreateFolder}>
          Create
        </Button>
      </div>
    </Collapse>
  );
};

export default FolderCreationForm;
