import React from 'react';
import { mdiFilter, mdiFilterOff } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';
import PropTypes from 'prop-types';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import P from 'router/paths';
import { useHistory } from 'react-router-dom';
import Icon from '@mdi/react';
import { Typography, Flex } from 'antd';

const ContactFormatter = ({ row }) => {
  const { setUrlParams, removeParams } = useQueryParams();
  const history = useHistory();

  const filterContact = () => {
    history.push(P.CRM.CONTACTS);
    removeParams(['contact_ids']);
    if (row?.contact?.email) {
      setUrlParams({ email: row.contact.email });
    } else if (row?.contact?.phone) {
      setUrlParams({ phone: row.contact.phone });
    } else {
      setUrlParams({ id: row.contact.id });
    }
  };

  return (
    <div className='flex items-center w-full'>
      {!row?.contact?.id ? (
        <Icon path={mdiFilterOff} color={Ninja.colors.xlight} className='m-auto' />
      ) : (
        <Flex
          align='center'
          className='bg-background-light p-1.5 rounded-md truncate h-8'
          gap={5}
          justify='space-between'
        >
          <TableIcons
            color={Ninja.colors.light}
            icons={[{ icon: mdiFilter, onClick: filterContact, tooltip: 'Contact', size: 0.7 }]}
          />
          {row?.contact?.email ? (
            <Typography className='text-xs flex-nowrap truncate text-ellipsis'>
              {row?.contact?.email}
            </Typography>
          ) : (
            row?.contact?.phone && (
              <Typography className='text-xs flex-nowrap truncate text-ellipsis'>
                {row?.contact?.phone}
              </Typography>
            )
          )}
        </Flex>
      )}
    </div>
  );
};

export default ContactFormatter;

ContactFormatter.propTypes = {
  row: PropTypes.object,
};
