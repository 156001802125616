import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useGetConfigMutation } from 'store/redux/states/filemanager/filemanager.api';

const useFileManagerConfig = (state) => {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [getConfig] = useGetConfigMutation();

  useEffect(() => {
    getConfig()
      .unwrap()
      .then((data) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setConfig(data);
          setLoading(false);
        });
      })
      .catch(() => {
        ReactDOM.unstable_batchedUpdates(() => {
          setConfig({});
          setLoading(false);
          setError(true);
        });
      });
  }, [state]);

  return { config, error, loading };
};

export default useFileManagerConfig;
