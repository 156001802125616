import { useDispatch, useSelector } from 'react-redux';
import {
  filemanager_setCurrentFolderAction,
  filemanager_setFolderCreationFormOpenedAction,
  filemanager_setSelectedFilesAction,
  filemanager_setStateAction,
  filemanager_setToolbarAction,
} from './filemanager.state';

export const useFileManagerToolbar = () => {
  const toolbar = useSelector((state) => state.filemanager.toolbar);
  const dispatch = useDispatch();

  const setToolbar = (new_toolbar) => {
    return dispatch(filemanager_setToolbarAction(new_toolbar));
  };

  return [toolbar, setToolbar];
};

export const useFileManagerCurrentFolder = () => {
  const current_folder = useSelector((state) => state.filemanager.current_folder);
  const dispatch = useDispatch();

  const setCurrentFolder = (new_folder) => {
    return dispatch(filemanager_setCurrentFolderAction(new_folder));
  };

  return [current_folder, setCurrentFolder];
};

export const useFileMangerFolderCreationFormOpened = () => {
  const folder_creation_form_opened = useSelector(
    (state) => state.filemanager.folder_creation_form_opened
  );
  const dispatch = useDispatch();

  const setOpened = (bool) => {
    return dispatch(filemanager_setFolderCreationFormOpenedAction(Boolean(bool)));
  };

  return [folder_creation_form_opened, setOpened];
};

export const useFileManagerSelectedFiles = () => {
  const selecteds = useSelector((state) => state.filemanager.selecteds);
  const dispatch = useDispatch();

  const toggle = (id) => {
    const new_arr = [...selecteds].toggle(id);
    return dispatch(filemanager_setSelectedFilesAction(new_arr));
  };

  const clear = () => {
    return dispatch(filemanager_setSelectedFilesAction([]));
  };

  const setSelected = (arr) => {
    return dispatch(filemanager_setSelectedFilesAction(arr));
  };

  return { selecteds, toggle, clear, setSelected };
};

export function useImageToCrop() {
  const state = useSelector((state) => {
    return {
      image_to_crop: state.filemanager.image_to_crop,
      crop_aspect_ratio: state.filemanager.crop_aspect_ratio,
      cropped_image: state.filemanager.cropped_image,
      cropper_title: state.filemanager.cropper_title,
      cropper_helper_text: state.filemanager.cropper_helper_text,
    };
  });

  const dispatch = useDispatch();

  /**
   * Set image which needs to be cropped
   * @param {Object} file
   */
  const setImageToCrop = (file) => {
    dispatch(
      filemanager_setStateAction({
        image_to_crop: file,
      })
    );
  };

  /**
   * Aspect ratio of cropper
   * @param {Number} crop_aspect_ratio
   */
  const setAspectRatio = (crop_aspect_ratio) => {
    dispatch(
      filemanager_setStateAction({
        crop_aspect_ratio: crop_aspect_ratio,
      })
    );
  };

  /**
   * Set cropped file data
   * @param {Object} cropped_image
   */
  const setCroppedImage = (cropped_image) => {
    dispatch(
      filemanager_setStateAction({
        cropped_image: cropped_image,
      })
    );
  };

  /**
   * Set cropper helper text
   * @param {Object} cropper_helper_text
   */
  const setCropperHelperText = (cropper_helper_text) => {
    dispatch(
      filemanager_setStateAction({
        cropper_helper_text: cropper_helper_text,
      })
    );
  };

  /**
   * Set title of croppers
   * @param {String} cropper_helper_text
   */
  const setCropperTitle = (cropper_helper_text) => {
    dispatch(
      filemanager_setStateAction({
        cropper_title: cropper_helper_text,
      })
    );
  };

  return {
    image_to_crop: state.image_to_crop,
    crop_aspect_ratio: state.crop_aspect_ratio,
    cropped_image: state.cropped_image,
    cropper_title: state.cropper_title,
    cropper_helper_text: state.cropper_helper_text,
    setImageToCrop,
    setCroppedImage,
    setAspectRatio,
    setCropperTitle,
    setCropperHelperText,
  };
}
