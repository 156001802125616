import React from 'react';
import PropTypes from 'prop-types';
import AdsetsTable from 'modules/adsets/tables/AdsetsTable';

const AccountInfoAdsetsTab = (props) => {
  const { dates, accountId, ...rest } = props;

  return (
    <div>
      <AdsetsTable
        {...rest}
        cardProps={{ disable: true }}
        defaultFilters={{
          network_account_id: accountId,
          ...dates,
        }}
      />
    </div>
  );
};

AccountInfoAdsetsTab.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountInfoAdsetsTab;
