import React from 'react';
import PropTypes from 'prop-types';
import NetworkConversionsTable from 'components/tables/conversions/NetworkConversionsTable';

const AccountInfoConversionsTab = (props) => {
  const { dates, accountId } = props;

  return (
    <div>
      <NetworkConversionsTable
        {...props}
        cardProps={{ disable: true }}
        title='Account conversions'
        defaultFilters={{ account_id: accountId, ...dates }}
      />
    </div>
  );
};

AccountInfoConversionsTab.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountInfoConversionsTab;
