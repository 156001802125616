/**
 * Converts given bytes to human readable string of file size
 * @param {Number} size
 * @param {'B'|'KB'|'MB'|'GB'} unit
 * @returns {String}
 */
export default function bytesToHuman(size, unit = 'MB') {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };

  if (!units[unit]) {
    console.error('Invalid unit provided. Please use B, KB, MB, or GB.');
    return null;
  }

  const fileSize = size / units[unit];
  return fileSize.toFixed(2) + ' ' + unit;
}
