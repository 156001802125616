import React, { useState } from 'react';
import { mdiContentSave } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { Space, Input, Button } from 'antd';

const EditableCellFormatter = ({ value, column, row, updateValue }) => {
  const [val, setVal] = useState(value);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      updateValue({
        leadId: row.id,
        fieldId: column.key,
        value: val,
      });
    }
  };

  return (
    <Space.Compact>
      <Input
        defaultValue={row[column.key]}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setVal(e.target.value);
        }}
        className='text-xs'
        size='small'
        suffix={
          <Button
            size='small'
            type='text'
            icon={<Icon path={mdiContentSave} onClick={handleKeyDown} className='text-info'/>}
          />
        }
      />
    </Space.Compact>
  );
};

export default EditableCellFormatter;

EditableCellFormatter.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  updateValue: PropTypes.func,
  value: PropTypes.string,
};
