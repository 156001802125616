import React, { useEffect, useState } from 'react';
import { Typography, Avatar, Popover, Flex } from 'antd';
import { useGetFormQuery } from 'store/redux/apis/crm.api';
import { useParams } from 'react-router-dom';
import { mdiChevronDown, mdiChevronRight, mdiPlusCircle } from '@mdi/js';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const OperatorPopoverFormatter = (props) => {
  const { onChange, value } = props;
  const [open, setOpen] = useState(false);
  const { id: formId } = useParams();
  const { data } = useGetFormQuery({ id: formId });
  const [options, setOptions] = useState([]);

  const handleOpenChange = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (Array.isArray(data?.users)) {
      setOptions(data.users);
    }
  }, [data, formId]);

  const selected = options.find((op) => op.id === value);

  const icons = [
    { icon: open ? mdiChevronRight : mdiChevronDown, size: 0.8, onClick: handleOpenChange },
  ];

  return (
    <Popover
      content={
        <div className='max-h-96 overflow-auto min-w-48'>
          {options.map((option) => (
            <div
              onClick={() => {
                onChange?.(option.id), setOpen(!open);
              }}
              key={option.id}
              value={option.id}
              className='cursor-pointer p-2 hover:bg-background-light rounded-md'
            >
              <div className='w-full h-full flex items-center p-2 border rounded justify-between'>
                <Flex align='center' gap='small'>
                  <Avatar alt='' src={option.avatar} size={28} className='border-2 border-blue' />
                  <div>
                    <Typography className='text-sm font-semibold'>{option.name}</Typography>
                  </div>
                </Flex>
              </div>
            </div>
          ))}
        </div>
      }
      trigger='click'
      open={open}
      placement='right'
      onOpenChange={handleOpenChange}
      className='cursor-pointer'
    >
      <div className='w-full relative flex '>
        {selected ? (
          <div className='w-full h-full flex items-center border rounded justify-between'>
            <Flex align='center' gap='small'>
              <Avatar alt='' src={selected.avatar} size={28} className='border-2 border-blue' />
              <div>
                <Typography className='text-sm font-semibold'>{selected.name}</Typography>
              </div>
            </Flex>
            <TableIcons color={Ninja.colors.light} icons={icons} />
          </div>
        ) : (
          <Flex className='w-full' justify='space-between' align='center' gap='small'>
            <Flex className='w-full' align='center' gap='small'>
              <Icon path={mdiPlusCircle} size={1.3} className='text-orange' />
              <Typography className='text-xs font-semibold'>Choose Operator</Typography>
            </Flex>
            <TableIcons color={Ninja.colors.light} icons={icons} />
          </Flex>
        )}
      </div>
    </Popover>
  );
};

export default OperatorPopoverFormatter;

OperatorPopoverFormatter.propTypes = {
  value: PropTypes.number,
};
