import { useMemo } from 'react';
import { useIntegrationListQuery } from './apps.api';

export default function useIntegrationStores(query = { active: true }) {
  const {
    data: allStoresResponse = [],
    isFetching,
    isError,
    refetch,
  } = useIntegrationListQuery(query);

  const allStores = Array.isArray(allStoresResponse)
    ? allStoresResponse
    : allStoresResponse?.data || [];

  const woocommerceAccounts = useMemo(() => {
    return allStores.filter((acc) => acc.type === 'woocommerce');
  }, [allStores]);

  const shopifyAccounts = useMemo(() => {
    return allStores.filter((acc) => acc.type === 'shopify');
  }, [allStores]);

  return {
    accounts: allStores,
    shopify: shopifyAccounts,
    woocommerce: woocommerceAccounts,
    isLoading: isFetching,
    isError,
    refetch,
  };
}

export const useAllIntegrationStores = (forselect = false) => {
  const { shopify, woocommerce, isLoading } = useIntegrationStores();

  if (isLoading) {
    return [];
  }

  if (forselect) {
    return [...shopify, ...woocommerce].reduce((prev, acc) => {
      prev[acc.id.toString()] = { name: acc.name, value: acc.id.toString() };
      return prev;
    }, {});
  }

  return [...google, ...facebook];
};
