import React from 'react';
import { Statistic } from 'antd';
import Ninja from '@ninja';
import Icon from '@mdi/react';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';

const RoasFormatter = ({ row: { roas = 0 } = {}, fontSize = 12, ...props }) => {
  const displayRoas = roas ?? 0;

  if (!displayRoas || displayRoas === 0) return <Statistic value={0} valueStyle={{ fontSize: fontSize }} {...props} />;

  return (
    <Statistic
      value={displayRoas}
      prefix={<Icon path={displayRoas > 1 ? mdiMenuUp : mdiMenuDown} />}
      valueStyle={{
        display: 'flex',
        alignItems: 'center',
        fontSize: fontSize,
        color: displayRoas > 1 ? Ninja.colors.green : Ninja.colors.red,
      }}
      {...props}
    />
  );
};

export default RoasFormatter;