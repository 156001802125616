import React, { Fragment } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const EditFormatter = ({ row }) => {
  const { setUrlParams } = useQueryParams();

  return (
    <Fragment>
      <Button
        onClick={() => setUrlParams({ edit: true, contact_id: row.id })}
        icon={<Icon path={mdiPencil} className='p-px text-color-light' />}
        type='text'
      />
    </Fragment>
  );
};

export default EditFormatter;

EditFormatter.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
