import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import Ninja from '@ninja';
import { useIntegrateWoocommerceStoreMutation } from 'modules/apps/apps/WoocommerceApp/api/woocommerce.api';
import { LinearProgress } from '@material-ui/core';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import ConnectedWoocommerceStoresTable from 'modules/apps/apps/WoocommerceApp/tables/ConnectedWoocommerceStoresTable';

const WoocommerceAppDrawer = (props) => {
  const { setUrlParams } = useQueryParams();
  const [user_id] = useState(Ninja.getUrlParam('user_id', false));
  const [store_id] = useState(Ninja.getUrlParam('store_id', false));
  const [integrateStoreQuery, { isLoading, isSuccess }] = useIntegrateWoocommerceStoreMutation();

  useEffect(() => {
    if (!user_id) return;

    integrateStoreQuery({
      user_id: user_id,
      store_id: store_id,
    }).unwrap();
  }, [user_id]);

  return (
    <AutomaticDrawer size='large' title='Woocommerce' {...props}>
      <div className='flex flex-col gap-4'>
        <div className='py-4'>
          <Button type='primary' onClick={() => setUrlParams({ connect: 'woocommerce' })}>
            Connect New Store
          </Button>
        </div>

        {isSuccess && (
          <div className='w-full'>
            <Alert type='success' message='Your store successfylly connected' />
          </div>
        )}

        {isLoading && (
          <div className='w-full flex flex-col'>
            Connecting...
            <LinearProgress />
          </div>
        )}

        <div>
          <ConnectedWoocommerceStoresTable />
        </div>
      </div>
    </AutomaticDrawer>
  );
};

WoocommerceAppDrawer.requiredParams = {
  app: (app) => 'woocommerce' === app,
};

export default WoocommerceAppDrawer;
