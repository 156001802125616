import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import PropTypes from 'prop-types';
import useWorkspace from 'store/redux/hooks/useWorkspace';

export default function JustValueFormatter(props) {
  const { workspace } = useWorkspace();
  const { currency } = props;
  let val = 0;

  // Get column value
  if (props.getValue) {
    val = props.getValue(props);
  } else {
    val = props.row[props.column.key];
  }

  if (val !== 0 && val !== null && val !== undefined) {
    val = Number(val);
    val = val % 1 !== 0 ? val.toFixed(2) : val; // only fix decimals if needed
  } else {
    val = 0;
  }

  return (
    <span className='font-normal text-xs'>
      {getFormattedValue(
        val,
        currency ? currency : props.row.currency ? props.row.currency : workspace.currency
      )}
    </span>
  );
}

JustValueFormatter.propTypes = {
  row: PropTypes.object,
  column: PropTypes.object,
  getValue: PropTypes.func,
  currency: PropTypes.string,
};
