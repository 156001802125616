import clsx from 'clsx';
import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

const SpentFormatter = ({ row: { ad_spent } }) => {
  return (
    <div>
      <span className='px-[7px] py-[5px] rounded-[12px] text-error !text-xs font-bold'>
        {getFormattedValue(ad_spent)}
      </span>
    </div>
  );
};

export default SpentFormatter;
